import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./PaymentConfirmation.css";

const PaymentConfirmation = () => {
  const id = useParams();
  const [isStatusFetched, setISF] = useState(false);
  const [isSucced, setIS] = useState(false);
  const handlePaymentConfirmation = async () => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    // REQUEST SIGNUP

    await fetch(process.env.REACT_APP_BACKEND_URL + "/confirm-pay", {
      method: "POST",
      headers: headers,
      // credentials: "include",
      body: JSON.stringify({
        transaction_id: id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setISF(true);
        if (data.message == "PAYMENT SUCCESS") {
          setIS(true);
        } else if (data.message == "PAYMENT FAILED") {
          setIS(false);
        }
      });
  };
  useEffect(() => {
    handlePaymentConfirmation();
  }, []);
  return (
    <div>
      {isStatusFetched ? (
        isSucced ? (
          <div>
            <div className="success">
              {" "}
              <div>
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  {" "}
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />{" "}
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <div>Payment Recived</div>
            </div>
          </div>
        ) : (
          <div>
            <div className="failed">
              <div>
                <svg
                  className="checkmark2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark_circle2"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark_check2"
                    fill="none"
                    d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"
                  />
                </svg>
              </div>
              <div>Payment failed</div>
            </div>
          </div>
        )
      ) : (
        <div id="PaymentStatusLoad">
          <div className="load"></div>
          <div>Getting Payment Status</div>
        </div>
      )}
      ;
    </div>
  );
};

export default PaymentConfirmation;
