import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FBAPI,
  authDomain: process.env.REACT_APP_FBAUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FBDBURL,
  projectId: process.env.REACT_APP_FBPROJECTID,
  storageBucket: process.env.REACT_APP_FBBUCKET,
  messagingSenderId: process.env.REACT_APP_FBSENDERID,
  appId: process.env.REACT_APP_FBAPPID,
  measurementId: process.env.REACT_APP_FBMEASURMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
export { auth, provider };
