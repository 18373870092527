import React, { useState } from "react";
import "./Faq.css";
import HomeChart from "./../images/Main/HomeChart.jpg";

const FAQ = [
  {
    index: 0,
    question: "Who is founder of VOE academy?",
    answer: "Alok Kumar",
  },
  {
    index: 1,
    question: "Who is co founder of VOE academy?",
    answer: "Anjali Birhman",
  },
  {
    index: 2,
    question: "Where is VOE located?",
    answer: "Sector 19,Panchkula,Haryana",
  },
  {
    index: 3,
    question: "When VOE academy was started?",
    answer: "24th Nov 2022",
  },
  {
    index: 4,
    question: "When VOE academy's business activities was established?",
    answer: "28th April 2023",
  },
  {
    index: 5,
    question: "Is VOE Academy is DPIIT certified?",
    answer: "Yes",
  },
  {
    index: 6,
    question: "Is VOE Academy is under MSME?",
    answer: "Yes",
  },
  {
    index: 7,
    question: "Is VOE Academy avalible in offline mode?",
    answer: "Yes,For tricity students but hopefully comming soon in your city.",
  },
  {
    index: 8,
    question: "Why VOE Academy is famous in tricity?",
    answer: "Because it is lead by group of engineers.",
  },
  {
    index: 9,
    question: "Is VOE Academy avalible in bihar?",
    answer: "Yes,Buxar",
  },
  {
    index: 11,
    question: "What is the full form of VOE?",
    answer: "Vibes of Education.",
  },
];

const Faq = () => {
  // State to manage open/closed state of each accordion item
  const [isOpen, setIsOpen] = useState(Array(8).fill(false));

  // Function to toggle accordion item open/closed
  const toggleAccordion = (index) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div id="FAQ" className="faqText">
      <h2 className="title">FAQ's</h2>
      <div id="FaqPage">
        <main className="FaqCard">
          <div className="acc-container">
            {FAQ.map((data) => (
              <div key={data.index}>
                <button
                  className={`acc-btn ${
                    isOpen[data.index] ? "is-open faqText" : "faqText"
                  }`}
                  onClick={() => toggleAccordion(data.index)}
                >
                  {/* Your button content */}
                  {`${data.index + 1}) ${data.question}`}
                </button>
                <div
                  className="acc-content"
                  style={{ maxHeight: isOpen[data.index] ? "1000px" : "0" }} // Adjust max height accordingly
                >
                  <p className="faqText">{`${data.answer}`}</p>
                </div>
              </div>
            ))}
          </div>
        </main>
        <div id="HomeChart">
          <img src={HomeChart} alt="" srcset="" />
        </div>
      </div>
    </div>
  );
};

export default Faq;
