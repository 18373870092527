import React from "react";
import "./Carrer.css";
import { Link } from "react-router-dom";

const Carrer = () => {
  return (
    <div>
      <div className="carrier">
        <h1>VOE CAREER PAGE</h1>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/results%2Fhiring.jpg?alt=media&token=5b43bd0b-209f-411b-b262-53892a8c9ae6"
          alt=""
          srcset=""
        />
        <h2>Currently we are hiring for the roles of:</h2>
        <ol className="career-list">
          <li>Social media manager</li>
          <li>Business Development Associates</li>
          <li>
            Subject Specialist (Hindi Medium)
            <ol type="a" className="sub-list">
              <li>Mathematics</li>
              <li>Science</li>
              <li>English</li>
            </ol>
          </li>
          <li>Sales executive</li>
        </ol>
        <ul className="requirments-list">
          <li>
            No degree required for all the above jobs. We will provide training
            free of cost
          </li>
          <li>Share your resume at voeacademy@gmail.com</li>
          <li>Salary 100 - 400 /hr.</li>
          <li>Experience : 0 - 1 years</li>
        </ul>
        <h2 id="OrCarrer">OR</h2>
        <ul className="requirments-list2">
          <li>Click on the link and fill the form</li>
          <Link
            to="https://forms.gle/xnWsFmRX8jNeUvfd6"
            id="GoogleFormLinkCareer"
          >
            Link
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Carrer;
