import React from "react";
import "./PaymentQR.css";
import LazyImage from "../Lazyimage/LazyImage";

const PaymentQR = () => {
  return (
    <div>
      <div>
        <h1 id="PaymentQRheading">
          Their is an issue in payment gateway you can pay on this qr and
          contact us for payment conformation
        </h1>
      </div>
      <div>
        <LazyImage
          key="QR"
          src="https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/images%2Fqr.jpg?alt=media&token=7efe3164-8ccb-45e0-9f11-0fea85d59534"
          className="lazy-image"
        />
      </div>
      <div>
        <p id="PayContact">
          Please contact on +91 6284518607 after paying the amount
        </p>
      </div>
    </div>
  );
};

export default PaymentQR;
