import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Query.css";
import ToastComponent from "../components/Toast/Toast";

const Query = () => {
  const [question, setQuestion] = useState("");
  const [toastmessage, setToastmessage] = useState(false);
  const [questionArray, setQuestionArray] = useState("");
  const [isQuesFetched, setQF] = useState(false);
  const [isSearchClicked, setISC] = useState(false);
  const addNewQuery = async () => {
    if (question === "") {
      //
      setToastmessage("PLEASE ENTER A QUESTION");
      setTimeout(() => setToastmessage(false), 1900);
      return;
    }

    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // REQUEST SIGNUP

    await fetch(process.env.REACT_APP_BACKEND_URL + "/addquery", {
      method: "POST",
      headers: headers,
      mode: "cors",
      body: JSON.stringify({
        question: question,
      }),
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.message == "ERROR LOGIN") {
          //
          setToastmessage("PLEASE LOGIN FIRST");
          setTimeout(() => setToastmessage(false), 1900);
          //
        } else {
          //
          setToastmessage(
            "YOUR QUERY IS LISTED WE WILL TRY TO SOLVE IT WITHIN 24 HOURS"
          );
          setTimeout(() => setToastmessage(false), 1900);
          //
        }
      });
  };
  const handleQueryResults = async (e) => {
    e.preventDefault();
    if (question === "") {
      //
      setToastmessage("PLEASE ENTER A QUESTION");
      setTimeout(() => setToastmessage(false), 1900);
      //
      return;
    }

    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // REQUEST SIGNUP

    await fetch("http://localhost:4000/getqueryresults", {
      method: "POST",
      headers: headers,
      mode: "cors",
      body: JSON.stringify({
        question: question,
      }),
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setISC(true);
        if (data.message == "RESULT") {
          setQuestionArray(data.questions);
          setQF(true);
        } else if (data.message == "NO RESULT") setQF(false);
      });
  };
  return (
    <div>
      <div id="AskQueryMainBox">
        <div className="askQueryBox">
          <form onSubmit={handleQueryResults}>
            <input
              className="askQuery"
              type="text"
              placeholder="Find your answers"
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
            />
            <button type="Submit" id="SearchQueryButton">
              SEARCH
            </button>
          </form>
        </div>
        {isSearchClicked ? (
          <div id="QuestionResult">
            {isQuesFetched ? (
              questionArray.map((result, idx) => {
                return (
                  <Link
                    to={`/QuerySolution/${result.id}`}
                    className="SearchResult"
                    key={idx}
                  >
                    {`${idx + 1}) ${result.question}`}
                  </Link>
                );
              })
            ) : (
              <div className="SearchResult"> NO RELATED QUESTION FOUND</div>
            )}
          </div>
        ) : (
          <div></div>
        )}

        <div id="AsknewquestionBox">
          <h2>IF YOUR QUESTION IS NOT THEIR</h2>
          <div id="NewQuestionButton" onClick={addNewQuery}>
            <h3>Ask new question</h3>
          </div>
        </div>
      </div>
      {toastmessage ? <ToastComponent message={toastmessage} /> : <></>}
    </div>
  );
};

export default Query;
