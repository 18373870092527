import React, { useEffect, useState } from "react";
import "./Home.css";
const Count = (props) => {
  const [count, setCount] = useState("0");
  useEffect(() => {
    let start = 0;
    // first three numbers from props
    const end = parseInt(props.number.substring(0, 3));
    // if zero, return
    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = parseInt(props.duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    // timer increments start counter
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + props.number.substring(3));
      if (start === end) clearInterval(timer);
    }, incrementTime);

    // dependency array
  }, [props.number, props.duration]);
  return (
    <li className="OnlinePresenceBoxTab">
      <div className="DailLiveBox">{count}+</div>
      <p>{props.label}</p>
    </li>
  );
};

export default Count;
