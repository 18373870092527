import React, { useEffect, useState } from "react";
import "./Books.css";
import BookTab from "./BookTab";

const Books = () => {
  const [bookData, setBooksData] = useState([]);
  const fetchBooks = async (type) => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    // REQUEST SIGNUP

    fetch(process.env.REACT_APP_BACKEND_URL + "/get-books", {
      method: "GET",
      headers: headers,
      body: JSON.stringify(),
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setBooksData(data.books);
        console.log(data);
      });
  };

  useEffect(() => {
    fetchBooks();
  }, []);
  return (
    <div className="booksSection">
      {bookData.map((Book, idx) => {
        return <BookTab Book={Book} id={idx} />;
      })}
    </div>
  );
};

export default Books;
