import React from "react";

const PDFViewer = ({ url }) => {
  return (
    <div>
      <iframe src={url} width="100%" height="500px" />
    </div>
  );
};
export default PDFViewer;
