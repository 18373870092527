import React, { useState } from "react";
import "./Products.css";
import LazyImage from "../Lazyimage/LazyImage";
import logo from "./../../images/logo.jpg";
import ToastComponent from "../Toast/Toast";

const ProductTab = (props) => {
  const [toastmessage, setToastmessage] = useState(false);

  const ProductPic = () => {
    if (props.Product.pic) {
      return (
        <div id="ProductPic">
          <LazyImage
            key={props.Product.product_id}
            src={props.Product.pic}
            className="lazy-image"
            id="ProductPicMain"
          />
        </div>
      );
    } else return <div></div>;
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const handleBuy = async (product_id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razropay failed to load!!");
      return;
    }

    let headers = new Headers();
    headers.append(
      "Access-Control-Allow-Origin",
      "https://www.voeacademy.com/"
    );
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    // REQUEST SIGNUP

    const data = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/buyproduct",
      {
        method: "post",
        headers: headers,
        body: JSON.stringify({
          product_id: product_id,
        }),
        credentials: "include",
      }
    ).then((res) => {
      return res.json();
    });

    if (data.message === "PLEASE LOGIN AGAIN") {
      setToastmessage("PLEASE LOGIN FIRST");
      setTimeout(() => setToastmessage(false), 1900);
    } else {
      const options = {
        key: process.env.REACT_APP_RAZORPAYAPI, // Enter the Key ID generated from the Dashboard
        amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "VOE Academy",
        description: "Please do the payment to buy the product",
        image:
          "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/images%2Flogo.jpg?alt=media&token=e1860acb-c4c3-424e-a564-b069478c6eda",
        order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          await fetch(process.env.REACT_APP_BACKEND_URL + "/confirm-pay", {
            method: "post",
            headers: headers,
            body: JSON.stringify({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            }),
            credentials: "include",
          }).then((res) => {
            return res.json();
          });
        },
        notes: {
          address: "Sector 19,Panchkula,Haryana",
        },
        theme: {
          color: "#332cf2",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };

  return (
    <div id={props.Product.product_id}>
      <div id="Products">
        <div id="MainProductBanner">
          <div id="ProductBanner1">
            <h4>{props.Product.product_name}</h4>
            <h5 id="ProductSubheading">{props.Product.price} RS</h5>
          </div>
          <ProductPic />
          {/* <img src={props.Product.pic} alt="" id="ProductPicMain" /> */}

          <div id="ProductBanner2">
            <h5>{props.Product.product_catagory}</h5>
            <div id="ProductBanner2Line"></div>
            <h5>{props.Product.class}</h5>
          </div>
          <ul id="ProductBanner3">
            {props.Product.discription.map((point) => {
              return <li>{point}</li>;
            })}
          </ul>

          <div id="ProductBanner5">
            {/* <h5
              onClick={() => handleBuy(props.Product.product_id)}
              className="buttonsProducts"
            >
              Buy now
            </h5> */}
            <h5
              onClick={() =>
                (window.location.href = "https://wa.me/918009129336")
              }
              className="buttonsCourses"
            >
              Buy now
            </h5>
          </div>
        </div>
      </div>
      {toastmessage ? <ToastComponent message={toastmessage} /> : <></>}
    </div>
  );
};

export default ProductTab;
