import React from "react";
import "./Team.css";

const TeamCard = (props) => {
  return (
    <div>
      <div className="card">
        <div className="card__side card__side--front">
          <div className="card__theme">
            <div className="card__theme-box">
              <p className="card__subject">{props.name}</p>
              <p className="card__title">
                <img src={props.pic} alt="" srcset="" />
              </p>
            </div>
          </div>
        </div>
        <div className="card__side card__side--back">
          <div className="card__cover">
            <h4 className="card__heading">
              <span className="card__heading-span">About</span>
            </h4>
          </div>
          <div className="card__details">
            {/* <ul>
              <li>{props.about}</li>
            </ul> */}
            <ul>
              <li>Experience : {props.about.experience}</li>
              <li>Designation : {props.about.designation}</li>
              <li>Qualification : {props.about.qualification}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
