import { useState } from "react";
import styles from "./Navbar.module.css";
import { Link } from "react-router-dom";
import logo from "./logo.png";

function Navbar() {
  // adding the states
  const [isActive, setIsActive] = useState(false);
  //add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };
  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false);
  };
  return (
    <div className={`${styles.App}`}>
      <header className="App-header">
        <nav className={`${styles.navbar}`}>
          {/* logo */}
          <a href="/" className={`${styles.logo}`}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/images%2FVoe%20logo.jpg?alt=media&token=6e6ac421-b3a7-4e9a-9064-64dbe6d87e56"
              alt=""
              id={`${styles.navlogo}`}
            />
          </a>
          <ul className={`${styles.navMenu} ${isActive ? styles.active : ""}`}>
            <li onClick={removeActive}>
              <Link to="/" className={`${styles.navLink}`}>
                Home
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/blogs" className={`${styles.navLink}`}>
                Blogs
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/services" className={`${styles.navLink}`}>
                Services
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/gallery" className={`${styles.navLink}`}>
                Gallery
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/team" className={`${styles.navLink}`}>
                Team
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/studymaterial" className={`${styles.navLink}`}>
                Study material
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/books" className={`${styles.navLink}`}>
                VOE store(Books)
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/career" className={`${styles.navLink}`}>
                Career
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/about" className={`${styles.navLink}`}>
                About
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/contactus" className={`${styles.navLink}`}>
                Contact Us
              </Link>
            </li>
            <li onClick={removeActive}>
              <Link to="/booksession" id={`${styles.bookSession}`}>
                Book Session
              </Link>
            </li>
          </ul>
          <div
            className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
            onClick={toggleActiveClass}
          >
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
            <span className={`${styles.bar}`}></span>
          </div>
        </nav>
      </header>
    </div>
  );
}
export default Navbar;
