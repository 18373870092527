import React, { useState, useEffect } from "react";
import "./Testimonal.css";
import Ishka from "./TestimonialImages/Ishika.png"
import Lalit from "./TestimonialImages/Lalit.png"
import Priyanshi from "./TestimonialImages/Priyanshi.png"
import Ratiram from "./TestimonialImages/Ratiram.png"

const Testimonial = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      plusSlides(1);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex]);

  function plusSlides(n) {
    setSlideIndex((prevIndex) => (prevIndex + n) % 4);
  }

  function currentSlide(n) {
    setSlideIndex(n - 1);
  }

  function showSlides(n) {
    const slides = document.getElementsByClassName("mySlides");
    const dots = document.getElementsByClassName("dot");
    if (n >= slides.length) {
      setSlideIndex(0);
      return;
    }
    if (n < 0) {
      setSlideIndex(slides.length - 1);
      return;
    }
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[n].style.display = "block";
    dots[n].className += " active";
  }

  return (
    <div>
      <div className="slideshow-container">
        <h1>What people says</h1>
        <div className="mySlides">
        <div className="TestimonialDivBox">
          <p>&ldquo;	</p>
          <img src={Priyanshi} className="TestimonialImage"></img>
          <p>&rdquo;	</p>
          </div>
          <q>
            I'm Priyanshi I found discipline, friendship, guidance, love, and
            everything in one person, and that is you 🙏 my teacher. You are the
            best teacher of my life. Thank you for making me what I am today🙏
          </q>
          <p className="author">- Priyanshi (CLASS-8th) , Odisha</p>
        </div>

        <div className="mySlides">
        <div className="TestimonialDivBox">
          <p>&ldquo;	</p>
          <img src={Ishka} className="TestimonialImage"></img>
          <p>&rdquo;	</p>
          </div>
          <q>
            I express my gratitude to VOE Academy for the numerous
            contributions.It has been made in support of my education. Thank you
            for the course. The lectures provided were worthy and i genuinely
            appreciate your work
          </q>
          <p className="author">- Ishika ( B.SC Nursing, Exam preparation)</p>
        </div>

        <div className="mySlides">
        <div className="TestimonialDivBox">
          <p>&ldquo;	</p>
          <img src={Ratiram}className="TestimonialImage"></img>
          <p>&rdquo;	</p>
          </div>
          <q>
            Hi, sir I really appreciate your willingness and skill, you had
            posses. I found Good communication with you. I have Got a lot of
            things after taking your classes. Thank You so much.
          </q>
          <p className="author">
            - Mr.Ratiram ,Jaipur(Rajasthan), Language Learning
          </p>
        </div>
        <div className="mySlides">
        <div className="TestimonialDivBox">
          <p>&ldquo;	</p>
          <img src={Lalit}className="TestimonialImage"></img>
          <p>&rdquo;	</p>
          </div>
          <q>
            I like your teaching style. I am very nervous about to share my
            ideas with others in English. But now i am excited and feel
            confident while sharing my ideas with others in just 6 month .I love
            your extempore activities.
          </q>
          <p className="author">
            - Lalit (HR), Sitarganj, Utrakhand, Language learning
          </p>
        </div>

        <button className="prev" onClick={() => plusSlides(-1)}>
          &#10094;
        </button>
        <button className="next" onClick={() => plusSlides(1)}>
          &#10095;
        </button>
      </div>

      <div className="dot-container">
        <span className="dot" onClick={() => currentSlide(1)}></span>
        <span className="dot" onClick={() => currentSlide(2)}></span>
        <span className="dot" onClick={() => currentSlide(3)}></span>
        <span className="dot" onClick={() => currentSlide(4)}></span>
      </div>
    </div>
  );
};

export default Testimonial;
