import React from "react";
import "./PdfViewer.css";

const PDFViewer2 = ({ url }) => {
  return (
    <div>
      <iframe src={url} width="400px" height="215px" id="PdfViewer2" />
    </div>
  );
};
export default PDFViewer2;
