import React, { useState } from "react";
import "./Signup.css";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../../components/Toast/Toast";
import { auth, provider } from "./config";
import {
  signInWithPopup,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";

const classes = ["1", "2", "3", "4", "5", "7", "8", "9", "10", "11", "12"];
const genders = ["Male", "Female"];

const Signup = () => {
  const navigate = useNavigate();
  const [toastmessage, setToastmessage] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [current_class, setClass] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [OTP, setOTP] = useState("");
  const [isOTPsent, setIsOTP] = useState(false);

  const handleGoogleClick = async () => {
    try {
      await signInWithPopup(auth, provider).then((data) => {
        let email_taken = data.user.email;
        let headers = new Headers();
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        fetch(process.env.REACT_APP_BACKEND_URL + "/isuserexist", {
          method: "POST",
          headers: headers,
          body: JSON.stringify({ email: data.user.email }),
          credentials: "include",
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (data.isexist) {
              window.location.reload();
            } else {
              setEmail(email_taken);
            }
          });
      });
    } catch (error) {}
  };

  const handleSignup = async () => {
    if (name === "" || email === "" || phone === "") {
      console.log("Please enter all input feilds");
    } else {
      // REQUEST SIGNUP
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      });
      signInWithPhoneNumber(auth, "+91" + phone, window.recaptchaVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          setVerificationId(confirmationResult.verificationId);
          setIsOTP(true);
        })
        .catch((error) => {
          console.log(error);
          // Error; SMS not sent
          // ...
        });
    }
  };
  const handleOTP = async () => {
    var credential = PhoneAuthProvider.credential(verificationId, OTP);
    const user = await signInWithCredential(auth, credential).catch(function (
      error
    ) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      if (errorCode === "auth/account-exists-with-different-credential") {
        alert("Email already associated with another account.");
        // Handle account linking here, if using.
      } else if (errorCode === "auth/invalid-verification-code") {
        setToastmessage("Wrong OTP");
        setTimeout(() => setToastmessage(false), 1900);
        // Handle account linking here, if using.
      } else {
        console.error(error);
      }
    });
    if (user) {
      let headers = new Headers();
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");
      // IS ALL INPUT FEILDS VALID

      await fetch(process.env.REACT_APP_BACKEND_URL + "/signup", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          gender: gender,
          current_class: current_class,
        }),
        credentials: "include",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setToastmessage(data.message);
          setTimeout(() => setToastmessage(false), 1900);
          if (data.message === "User successfully signed up!") {
            window.location.reload();
            navigate("/");
          }
        });
    }
  };

  return (
    <div id="SignupPage">
      {Boolean(email === "") ? (
        <div onClick={handleGoogleClick} id="GoogleSignin">
          <h1>Welcome </h1>
          <h2>to</h2>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/images%2FVoe%20logo.jpg?alt=media&token=6e6ac421-b3a7-4e9a-9064-64dbe6d87e56"
            alt=""
          />
          <button type="button" className="login-with-google-btn">
            Sign in with Google
          </button>
        </div>
      ) : (
        <div id="Signup">
          <h2>SIGN UP</h2>
          <div
            className="SignupBox"
            style={{ display: isOTPsent ? "none" : "flex" }}
          >
            <div>
              <div className="FormIcons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  id="profile"
                >
                  <g>
                    <circle cx="16" cy="6.96" r="6"></circle>
                    <path d="M30.86,26.84a15.07,15.07,0,0,0-4.11-7.47A12.47,12.47,0,0,0,25.13,18,15,15,0,0,0,16,15,15.24,15.24,0,0,0,5.24,19.37a15.07,15.07,0,0,0-4.11,7.47,3.42,3.42,0,0,0,.69,2.88A3.52,3.52,0,0,0,4.58,31H27.42a3.52,3.52,0,0,0,2.75-1.32A3.42,3.42,0,0,0,30.86,26.84Z"></path>
                  </g>
                </svg>
              </div>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div>
              <div className="FormIcons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  id="class"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 4h2v5l-1-.75L9 9V4zm9 16H6V4h1v9l3-2.25L13 13V4h5v16z"></path>
                </svg>
              </div>
              <select
                value={current_class}
                onChange={(e) => setClass(e.target.value)}
              >
                <option value="" className="formOptions">
                  Select Class
                </option>
                {classes.map((date) => {
                  return (
                    <option className="formOptions" value={date}>
                      {date}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div className="FormIcons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  id="gender"
                >
                  <path d="M44.5,15.67v6.72a.5.5,0,0,1-.5.5H41.95a.5.5,0,0,1-.5-.5V20.38l-2.03,2.03a11.081,11.081,0,0,1,2.23,6.67c0,.34-.02.68-.04,1.01a.584.584,0,0,1,.02.19A11.062,11.062,0,0,1,22.8,37.01a11.137,11.137,0,0,1-3.2-6.92c-.01-.11-.02-.22-.02-.33a12.2,12.2,0,0,0,3.14-2.1,7.428,7.428,0,0,0-.13,1.42,8.01,8.01,0,1,0,13.67-5.66,8.62,8.62,0,0,0-1.89-1.41h-.01a8.083,8.083,0,0,0-2.19-.79,8.192,8.192,0,0,0-1.57-.15,8.045,8.045,0,0,0-4.45,1.36,7.588,7.588,0,0,0-1.21.99,7.028,7.028,0,0,0-.52.57,7.632,7.632,0,0,0-.73,1.07c-.02.01-.03.02-.02.03a10.771,10.771,0,0,1-2.28,2.39,12.913,12.913,0,0,1-1.78,1.15c-.01.01-.02.01-.03.02a.612.612,0,0,1-.12.05,10.77,10.77,0,0,1-3.34,1.04.06.06,0,0,1-.04.01v2.29H18.3a.5.5,0,0,1,.5.5v2.05a.5.5,0,0,1-.5.5H16.08v2.25a.508.508,0,0,1-.5.5H13.53a.5.5,0,0,1-.5-.5V35.09H10.82a.5.5,0,0,1-.5-.5V32.54a.5.5,0,0,1,.5-.5h2.21V29.75A11,11,0,0,1,3.5,18.8a11.133,11.133,0,0,1,2.04-6.4,11,11,0,0,1,9.02-4.65,11.325,11.325,0,0,1,1.68.13,10.947,10.947,0,0,1,7.99,5.56v.01a10.937,10.937,0,0,1,1.32,4.26v.05c.01.12.03.24.03.36a9.971,9.971,0,0,0-1.33.72,11.331,11.331,0,0,0-1.82,1.38A8,8,0,1,0,7.66,22.87a8.113,8.113,0,0,0,4.65,3.62,8.632,8.632,0,0,0,.88.2,7.971,7.971,0,0,0,7.58-2.85c.01-.01.01-.01.01-.02.1-.12.2-.25.29-.38.01-.01.01-.02.03-.03.021-.043.308-.453.33-.52,0,0,.006-.007.01-.01a11,11,0,0,1,2.81-2.85,8.913,8.913,0,0,1,.82-.52,9.739,9.739,0,0,1,1.92-.88,11.093,11.093,0,0,1,10.27,1.63l2.03-2.04H37.28a.5.5,0,0,1-.5-.5V15.67a.5.5,0,0,1,.5-.5H44A.5.5,0,0,1,44.5,15.67Z"></path>
                </svg>
              </div>
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" className="formOptions">
                  Select Gender
                </option>
                {genders.map((date) => {
                  return (
                    <option className="formOptions" value={date}>
                      {date}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <div className="FormIcons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="phone"
                  x="0"
                  y="0"
                  version="1.1"
                  viewBox="0 0 29 29"
                >
                  <path d="m20.914 17.743-2.091 1.178a1.319 1.319 0 0 1-1.58-.217l-6.979-6.979a1.32 1.32 0 0 1-.217-1.58l1.178-2.091a1.978 1.978 0 0 0-.325-2.37L7.766 2.55a1.978 1.978 0 0 0-2.798 0L3.545 3.972a5.276 5.276 0 0 0-.793 6.446l.714 1.19a41.36 41.36 0 0 0 14.946 14.631l.141.081c2.102 1.201 4.699.851 6.382-.831l1.486-1.486a1.978 1.978 0 0 0 0-2.798l-3.136-3.136a1.978 1.978 0 0 0-2.371-.326z"></path>
                </svg>
              </div>
              <input
                type="text"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            {/* <div>
              <div className="FormIcons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 24 24"
                  viewBox="0 0 24 24"
                  id="padlock"
                >
                  <path
                    d="M17,9V7c0-2.8-2.2-5-5-5S7,4.2,7,7v2c-1.7,0-3,1.3-3,3v7c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3v-7C20,10.3,18.7,9,17,9z M9,7
	c0-1.7,1.3-3,3-3s3,1.3,3,3v2H9V7z"
                  ></path>
                </svg>
              </div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div> */}
            <p className="ErrorMessage">{ErrorMessage}</p>
            <button onClick={handleSignup} id="sign-in-button">
              Sign Up
            </button>
          </div>
          <div
            className="SignupBox"
            style={{ display: isOTPsent ? "flex" : "none" }}
          >
            <div>
              <div className="FormIcons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M22 11V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C20.48 5 19.92 5 18.8 5H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 6.52 2 7.08 2 8.2v3.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 15 4.08 15 5.2 15H11m1-5h.005M17 10h.005M7 10h.005m12.245 7v-1.75a1.75 1.75 0 1 0-3.5 0V17m-3.5-7a.25.25 0 1 1-.5 0 .25.25 0 0 1 .5 0Zm5 0a.25.25 0 1 1-.5 0 .25.25 0 0 1 .5 0Zm-10 0a.25.25 0 1 1-.5 0 .25.25 0 0 1 .5 0Zm8.35 11h3.8c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437C21 20.24 21 19.96 21 19.4v-.8c0-.56 0-.84-.109-1.054a1 1 0 0 0-.437-.437C20.24 17 19.96 17 19.4 17h-3.8c-.56 0-.84 0-1.054.109a1 1 0 0 0-.437.437C14 17.76 14 18.04 14 18.6v.8c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C14.76 21 15.04 21 15.6 21Z"
                    className="colorStroke000 svgStroke"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                placeholder="OTP"
                value={OTP}
                onChange={(e) => setOTP(e.target.value)}
              />
            </div>
            <button onClick={handleOTP}>VERIFY OTP</button>
          </div>
        </div>
      )}
      {toastmessage ? <ToastComponent message={toastmessage} /> : <></>}
    </div>
  );
};

export default Signup;
