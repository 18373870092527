import React from "react";
import "./RegisterAsTutor.css";

const RegisterAsTutor = () => {
  return (
    <div>
      <div className="carrier">
        <h1>Register as tutor</h1>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/images%2FVoe%20logo.jpg?alt=media&token=6e6ac421-b3a7-4e9a-9064-64dbe6d87e56"
          alt=""
          srcset=""
          id="VOE_LOGO_RAT"
        />
        <ol className="career-list">
          <li>Certificate of Association.</li>
          <li>Access to 1000+ students.</li>
          <li>Timely payments.</li>
          <li>Incentive after completing 100+ hours of teaching.</li>
          <li>Get included in VOE permanent member.</li>
        </ol>

        <ul className="requirments-list2">
          <li>Click on the link and fill the form</li>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfIQK1CVYNwdue78ucNvztGW1sQLR6FTA2hl1RM7Ng29eWHFw/viewform"
            id="GoogleFormLinkCareer"
          >
            Link
          </a>
        </ul>
      </div>
    </div>
  );
};

export default RegisterAsTutor;
