import React from "react";
import "./../Blogs.css";

const Jnvst = () => {
  return (
    <div id="Blog">
      <header className="blog-header">
        <h1>Navodaya Vidyalaya Samiti Selection Test (JNVST) for Class 9</h1>
      </header>
      <div>
        <main className="main-content">
          <section className="exam-overview">
            <h2>Exam Overview</h2>
            <ul>
              <li>
                <strong>Name:</strong> Jawahar Navodaya Vidyalaya Selection Test
                (JNVST)
              </li>
              <li>
                <strong>Target Audience:</strong> Students seeking admission to
                Class 9 in Jawahar Navodaya Vidyalayas
              </li>
              <li>
                <strong>Level:</strong> School Entrance Exam
              </li>
              <li>
                <strong>Subjects:</strong> English, Hindi, Maths, General
                Science
              </li>
              <li>
                <strong>Total Duration:</strong> 2.5 hours
              </li>
              <li>
                <strong>Selection Criteria:</strong> Merit list based on marks
                in Mathematics, Science, and the higher score between English
                and Hindi.
              </li>
              <li>
                <strong>No Negative Marking</strong>
              </li>
              <li>
                <strong>Exam Date (2024):</strong> February 10th
              </li>
              <li>
                <strong>Part of:</strong> Prerna Programme (for classes 9 to 12)
              </li>
            </ul>
          </section>
          <section className="syllabus-subtopics">
            <h2>Syllabus and Subtopics</h2>
            <p>The syllabus covers foundational concepts in core subjects:</p>
            <ul>
              <li>
                <strong>English:</strong> Basic grammar, comprehension,
                vocabulary building, and writing skills.
              </li>
              <li>
                <strong>Hindi:</strong> Similar to English, focusing on core
                grammar, comprehension, vocabulary, and writing.
              </li>
              <li>
                <strong>Maths:</strong>
                <ul>
                  <li>Rational numbers</li>
                  <li>Linear equations</li>
                  <li>Quadrilaterals</li>
                  <li>Data interpretation</li>
                  <li>Squares and roots</li>
                  <li>Cubes and roots</li>
                  <li>Comparison of numbers</li>
                  <li>Algebra</li>
                  <li>Solid shapes</li>
                  <li>Mensuration</li>
                  <li>Exponents and powers</li>
                </ul>
              </li>
              <li>
                <strong>Science:</strong>
                <ul>
                  <li>
                    <strong>Biology:</strong>{" "}
                    <ul>
                      <li>Geoproduction</li>
                      <li>Microorganisms</li>
                      <li>Cell structure and function</li>
                      <li>Conservation efforts</li>
                      <li>Reproduction in plants and animals</li>
                      <li>Adolescent development</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Physics:</strong>{" "}
                    <ul>
                      <li>Force and pressure</li>
                      <li>Friction</li>
                      <li>Sound</li>
                      <li>Chemical effects of electric current</li>
                      <li>Light</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Chemistry:</strong>{" "}
                    <ul>
                      <li>Fibers and plastics</li>
                      <li>Metals and non-metals</li>
                      <li>Coal and petroleum</li>
                      <li>Combustion</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Miscellaneous:</strong>{" "}
                    <ul>
                      <li>Natural phenomena</li>
                      <li>Stars and the solar system</li>
                      <li>Pollution</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </section>
          <section className="additional-notes">
            <h2>Additional Notes</h2>
            <ul>
              <li>
                The specific weightage of marks from each subject or subtopic
                might not be publicly available.
              </li>
              <li>
                It's recommended to refer to official Navodaya Vidyalaya Samiti
                resources for the most up-to-date information about the JNVST
                exam.
              </li>
            </ul>
          </section>
          <section className="exam-preparation-resources">
            <h2>Resources for Exam Preparation</h2>
            <ul>
              <li>
                <strong>Navodaya Vidyalaya Samiti Website:</strong>{" "}
                <a href="http://www.navodaya.gov.in/">
                  http://www.navodaya.gov.in/
                </a>{" "}
                - Check for official notifications, syllabus, and potentially
                sample papers.
              </li>
              <li>
                <strong>Coaching Institutes:</strong> Explore resources from
                coaching institutes that prepare students for the JNVST exam.
                They might offer insights on question difficulty and previous
                years' trends.
              </li>
              <li>
                <strong>Online Resources:</strong> Search online for JNVST
                preparation materials, practice questions, and previous years'
                papers (if available).
              </li>
            </ul>
          </section>
        </main>
      </div>
      <footer className="blog-footer">
        <p>Good luck with your exam preparation!</p>
      </footer>
    </div>
  );
};

export default Jnvst;
