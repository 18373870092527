import React from "react";
import "./../../Blogs.css";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

const NSEC = () => {
  return (
    <div id="Blog">
      <Helmet>
        <title>
          Rashtriya Madhyamik Shiksha Abhiyan: A Step Towards Quality Education
        </title>
        <meta
          name="description"
          content="Discover how Rashtriya Madhyamik Shiksha Abhiyan (RMSA) is transforming secondary education in India by focusing on enrollment, quality, and equity. Learn about its objectives, achievements, and future goals."
        />
      </Helmet>
      <header className="blog-header">
        <h1>
          Rashtriya Madhyamik Shiksha Abhiyan: A Step Towards Quality Education
        </h1>
      </header>
      <div>
        <main className="main-content">
          <section className="exam-overview">
            <h2>Introduction</h2>
            <ul>
              <li>
                Building on the success of SSA, the Indian government introduced
                the Rashtriya Madhyamik Shiksha Abhiyan (RMSA) in 2009,
                targeting secondary education. Today, we’ll explore the
                objectives, achievements, and ongoing challenges of RMSA,
                ensuring that our readers are well-informed about this
                significant educational initiative.
              </li>
            </ul>
            {/* <img src={NSEC1} alt="" /> */}
          </section>
          <section className="syllabus-subtopics">
            <h2>Background and Objectives</h2>
            <ul>
              <li>
                RMSA was introduced to bridge the gap left by SSA, focusing on
                children in the 14-18 age group. The primary objectives of RMSA
                are to enhance access to secondary education, improve its
                quality, and ensure equity for all students. Key goals include:
              </li>
              <ul>
                <li>
                  Increasing Enrollment: Ensuring that every child between 14-18
                  years is enrolled in school.
                </li>
                <li>
                  Retention: Reducing dropout rates and retaining students
                  through secondary education.
                </li>
                <li>
                  Quality Education: Providing a robust curriculum with a strong
                  emphasis on science, math, and English.
                </li>
                <li>
                  Infrastructure Development: Building additional classrooms,
                  laboratories, and libraries to support the educational
                  environment.
                </li>
              </ul>{" "}
            </ul>
          </section>
          <section>
            <h1>
              <strong>Achievements and Challenges</strong>
            </h1>
          </section>
          <section className="additional-notes">
            <h2>Enrollment and Retention </h2>
            <ul>
              {" "}
              <li>
                Since its inception, RMSA has made significant strides in
                increasing enrollment rates. In regions where enrollment was
                around 52.2%, it has climbed to 60% and is targeted to reach 75%
                in the near future. However, challenges remain in ensuring that
                schools are accessible to all students. For instance, schools
                should be within a reasonable distance from students' homes, and
                where this is not feasible, transportation or hostel facilities
                must be provided.
              </li>
            </ul>
          </section>
          <section className="exam-preparation-resources">
            <h2>Quality of Education</h2>
            <ul>
              <li>
                Quality education is a critical focus of RMSA. The program aims
                to enhance the learning experience by:
              </li>
              <ul>
                <li>
                  Hiring Additional Teachers: Increasing the number of qualified
                  teachers to improve student-teacher ratios.
                </li>
                <li>
                  Teacher Training: Offering high-level training programs to
                  equip teachers with the skills needed to effectively teach and
                  manage classrooms.
                </li>
                <li>
                  Infrastructure Improvements: Upgrading science laboratories
                  and integrating ICT (Information and Communication Technology)
                  into the curriculum through smart classes and digital learning
                  tools.
                </li>
              </ul>
            </ul>
          </section>
          <section className="exam-preparation-resources">
            <h2>Equity and Inclusiveness </h2>
            <ul>
              <li>
                RMSA places a strong emphasis on equity, ensuring that
                socio-economic barriers do not hinder access to education.
                Special drives are conducted to enroll students from weaker
                sections and minority communities. Additionally, schools in
                remote and underserved areas receive priority for infrastructure
                improvements and resource allocation.
              </li>
            </ul>
          </section>
          <section className="exam-preparation-resources">
            <h2>Implementation and Funding </h2>
            <ul>
              <li>
                RMSA is a centrally sponsored scheme, meaning the central
                government covers 75% of the expenses, with the remaining 25%
                funded by the states (90% in the case of North Eastern and
                Himalayan states). This funding model ensures that even
                financially weaker states can implement the program effectively.
                The scheme also includes special provisions for states with
                unique geographical challenges, such as the Himalayan and North
                Eastern states.
              </li>
            </ul>
          </section>
          <section className="exam-preparation-resources">
            <h2>Future Prospects</h2>
            <ul>
              <li>
                Looking ahead, RMSA aims to achieve universal retention by 2030,
                ensuring that no child drops out of school prematurely. The
                focus will continue to be on quality enhancement, with ongoing
                investments in teacher training, infrastructure, and digital
                education tools. Micro-planning will be essential to tailor
                solutions to local needs, ensuring that every school can provide
                a high-quality education.
              </li>
            </ul>
          </section>
          <section>
            <h2>Conclusion</h2>
            <ul>
              <li>
                Rashtriya Madhyamik Shiksha Abhiyan is a crucial step towards
                ensuring that every child in India has access to quality
                secondary education. By addressing enrollment, retention, and
                quality issues, RMSA aims to build a robust educational
                foundation for future generations. If you found this post
                informative, please like, share, and subscribe to stay updated
                on educational initiatives and other important topics.
              </li>

              <li>
                Don’t forget to follow us on social media for more updates!
              </li>
            </ul>
          </section>
          <section>
            <ul>
              <li>
                Visit{" "}
                <a href="https://www.education.gov.in/rmsa_integrated">here</a>{" "}
                for further information on RMSA.
              </li>
            </ul>
          </section>
        </main>
      </div>
      <footer className="blog-footer">
        <p>To know more about other schemes</p>
        <a href="https://www.youtube.com/@VOEACADEMY">
          <strong>CLICK HERE</strong>
        </a>
      </footer>
    </div>
  );
};

export default NSEC;
