import React, { useEffect, useState } from "react";
import "./Products.css";
import { Link } from "react-router-dom";
import ProductTab from "./ProductTab";

const Products = () => {
  const [ProductsData, setProductData] = useState([]);
  const fetchProducts = async (type) => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    // REQUEST SIGNUP

    fetch(process.env.REACT_APP_BACKEND_URL + "/getproducts", {
      method: "GET",
      headers: headers,
      body: JSON.stringify(),
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setProductData(data.products);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div>
      <div id="ProductNav">
        <ul>
          <li>
            <Link to="#">
              <h4 className="ActiveNav">All Products </h4>
            </Link>{" "}
          </li>
        </ul>
      </div>
      <div id="ProductBox">
        {ProductsData.map((Product, idx) => {
          if (Product.class === "10") {
            return <ProductTab Product={Product} id={idx} />;
          }
        })}
        {ProductsData.map((Product, idx) => {
          if (Product.class === "9") {
            return <ProductTab Product={Product} id={idx} />;
          }
        })}
        {ProductsData.map((Product, idx) => {
          if (Product.class === "Any") {
            return <ProductTab Product={Product} id={idx} />;
          }
        })}
      </div>
    </div>
  );
};

export default Products;
