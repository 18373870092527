import React from "react";
import "./../Blogs.css";

const CHSBHUEntranceExam = () => {
  return (
    <div id="Blog">
      <header className="blog-header">
        <h1>CHS BHU Entrance Exam (2024)</h1>
      </header>
      <div>
        <main className="main-content">
          <section className="exam-details">
            <h2>Exam Details</h2>
            <ul>
              <li>
                <strong>Name:</strong> CHS BHU Entrance Exam
              </li>
              <li>
                <strong>Target Audience:</strong> Class 9 & 11 Admission
              </li>
              <li>
                <strong>Level:</strong> School Entrance Exam
              </li>
              <li>
                <strong>Total Marks:</strong> 100 (20 marks/subject)
              </li>
              <li>
                <strong>Subjects:</strong> English, Hindi, GK, Maths, Science
              </li>
              <li>
                <strong>Exam Dates (2024):</strong>
              </li>
              <ul>
                <li>
                  <strong>Application:</strong> March 20th
                </li>
                <li>
                  <strong>Exam:</strong> Last week of April - First week of May
                </li>
                <li>
                  <strong>Results:</strong> Second week of June
                </li>
              </ul>
              <li>
                <strong>Number of Seats:</strong> 110 (Specific distribution
                unavailable)
              </li>
            </ul>
            <table className="expectedCutoff">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Boys</th>
                  <th>Girls</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>GEN</td>
                  <td>225</td>
                  <td>231</td>
                </tr>
                <tr>
                  <td>EWS</td>
                  <td>195</td>
                  <td>216</td>
                </tr>
                <tr>
                  <td>OBC</td>
                  <td>216</td>
                  <td>225</td>
                </tr>
                <tr>
                  <td>SC</td>
                  <td>186</td>
                  <td>195</td>
                </tr>
                <tr>
                  <td>ST</td>
                  <td>123</td>
                  <td>153</td>
                </tr>
              </tbody>
            </table>
          </section>
          <section className="blog-syllabus">
            <h2>Syllabus and Subtopics</h2>
            <p>
              The syllabus covers foundational knowledge in various subjects:
            </p>
            <ul>
              <li>
                <strong>English:</strong> Grammar, Comprehension, Vocabulary,
                Writing
              </li>
              <li>
                <strong>Hindi:</strong> Similar focus on core grammar,
                comprehension, etc.
              </li>
              <li>
                <strong>GK:</strong> Indian history, civics, geography, current
                affairs, basic science.
              </li>
              <li>
                <strong>Maths:</strong>
              </li>
              <ul>
                <li>Rational numbers</li>
                <li>Linear equations</li>
                <li>Quadrilaterals</li>
                <li>Data interpretation</li>
                <li>Squares and roots</li>
                <li>Cubes and roots</li>
                <li>Comparison of numbers</li>
                <li>Algebra</li>
                <li>Solid shapes</li>
                <li>Mensuration</li>
                <li>Exponents and powers</li>
              </ul>
              <li>
                <strong>Science:</strong>
              </li>
              <ul>
                <li>
                  Biology:
                  <ul>
                    <li>Geoproduction</li>
                    <li>Microorganisms</li>
                    <li>Cell structure and function</li>
                    <li>Conservation of plants and animals</li>
                    <li>Reproduction</li>
                    <li>Adolescent development</li>
                  </ul>
                </li>
                <li>
                  Physics:
                  <ul>
                    <li>Force and pressure</li>
                    <li>Friction</li>
                    <li>Sound</li>
                    <li>Chemical effects of electric current</li>
                    <li>Light</li>
                  </ul>
                </li>
                <li>
                  Chemistry:
                  <ul>
                    <li>Fibers and plastics</li>
                    <li>Metals and non-metals</li>
                    <li>Coal and petroleum</li>
                    <li>Combustion</li>
                  </ul>
                </li>
                <li>
                  Miscellaneous:
                  <ul>
                    <li>Natural phenomena</li>
                    <li>Stars and the solar system</li>
                    <li>Pollution</li>
                  </ul>
                </li>
              </ul>
            </ul>
          </section>

          <section className="blog-tips">
            <h2>Tips for Further Preparation</h2>
            <ul>
              <li>
                <strong>Official BHU Website:</strong>{" "}
                <a href="https://www.bhuonline.in/">
                  https://www.bhuonline.in/
                </a>
              </li>
              <li>
                <strong>Coaching Institutes:</strong> Explore resources for exam
                preparation insights.
              </li>
              <li>
                <strong>Online Forums:</strong> Participate in communities for
                experiences and trends.
              </li>
              <li>
                <strong>Previous Years' Papers:</strong> Understand question
                format and difficulty.
              </li>
              <li>
                <strong>Mock Tests:</strong> Practice time management and
                identify improvement areas.
              </li>
              <li>
                <strong>Study Resources:</strong> Textbooks, reference materials
                aligned with the syllabus.
              </li>
            </ul>
          </section>
        </main>
      </div>
      <footer className="blog-footer">
        <p>Good luck with your exam preparation!</p>
      </footer>
    </div>
  );
};

export default CHSBHUEntranceExam;
