import React, { useEffect, useState } from "react";
import logo from "./../images/logo.jpg";
import "./BookSession.css";

import TeachersInfo from "./TeachersInfo";
import ToastComponent from "../components/Toast/Toast";
import { useNavigate } from "react-router-dom";

const BookSession = () => {
  const navigate = useNavigate();
  const [toastmessage, setToastmessage] = useState(false);
  const [subject, setSubject] = useState("");
  const [studing_class, setStudingClass] = useState("");
  const [teacher, setTeacher] = useState("");
  const [teachersInfo, setTeachersInfo] = useState("");
  const [teachersData, setTeachersData] = useState("");
  const [classNumber, setClassNumber] = useState();
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const getTeachers = async () => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // REQUEST SIGNUP

    await fetch(process.env.REACT_APP_BACKEND_URL + "/getteachers", {
      method: "get",
      headers: headers,
      mode: "cors",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTeachersData(data.teachers);
      });
  };

  const handleBookSession = async () => {
    if (subject === "" || studing_class === "" || classNumber === "") {
      console.log("Please enter all input feilds");
      return;
    } else if (classNumber < 1) {
      console.log("Please select atleast one class number");
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razropay failed to load!!");
      return;
    }
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // REQUEST SIGNUP

    const data = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/booksession",
      {
        method: "POST",
        headers: headers,
        mode: "cors",
        body: JSON.stringify({
          subject: subject,
          studing_class: studing_class,
          no_of_classes: classNumber,
        }),
        credentials: "include",
      }
    ).then((res) => {
      return res.json();
    });
    console.log(data);
    if (data.message === "PLEASE LOGIN AGAIN") {
      setToastmessage("PLEASE LOGIN FIRST");
      setTimeout(() => setToastmessage(false), 1900);
    } else {
      const options = {
        key: process.env.REACT_APP_RAZORPAYAPI, // Enter the Key ID generated from the Dashboard
        amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "VOE Academy",
        description: "Test Transaction",
        image:
          "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/images%2Flogo.jpg?alt=media&token=e1860acb-c4c3-424e-a564-b069478c6eda",
        order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          await fetch(
            process.env.REACT_APP_BACKEND_URL + "/confirm-pay-session",
            {
              method: "post",
              headers: headers,
              body: JSON.stringify({
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
              }),
              credentials: "include",
            }
          ).then((res) => {
            return res.json();
          });
        },

        notes: {
          address: "Sector 19,Panchkula,Haryana",
        },
        theme: {
          color: "#332cf2",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };

  useEffect(() => {
    getTeachers();
  }, []);

  return (
    <div id="BookSessionPage">
      {teacher === "" ? (
        <div id="SelectTeacherBox">
          <h1 id="SelectTeacherHeading">Select your teacher</h1>
          {teachersData === "" ? (
            <div>
              <h2>FETCHING TEACHERS DATA</h2>
            </div>
          ) : (
            <div id="TeachersToSelect">
              <h1>Language</h1>
              {teachersData.map((teacher, idx) => {
                if (teacher["Language Learning"]) {
                  return (
                    <div id={idx} className="TeachersInfoTab">
                      <div>
                        <img
                          src={teacher["teacher_profile_url"]}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div id="TeachersDetailBox">
                        <div>
                          <h2>{teacher["teacher_name"]}</h2>
                        </div>
                        <div>
                          <h3>Rating : {teacher["teacher_rating"]}</h3>
                          <div
                            onClick={() => {
                              setTeachersInfo(teacher);
                              window.scrollTo(0, 0);
                            }}
                          >
                            View Teacher Profile
                          </div>
                        </div>

                        <div>
                          <div
                            id="BookClassBtnTeacher"
                            // onClick={async () => {
                            //   setTeacher(teacher);
                            //   if (teacher["Language Learning"]) {
                            //     setSubjects(teacher["Language Learning"]);
                            //     setClasses(
                            //       teacher["Language Learning-classes"]
                            //     );
                            //   }
                            // }}
                            onClick={() => {
                              window.location.href =
                                "https://wa.me/+918009129336";
                            }}
                          >
                            <p> Book Class</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              <h1>Science</h1>
              {teachersData.map((teacher, idx) => {
                if (teacher["Science"]) {
                  return (
                    <div id={idx} className="TeachersInfoTab">
                      <div>
                        <img
                          src={teacher["teacher_profile_url"]}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div id="TeachersDetailBox">
                        <div>
                          <h2>{teacher["teacher_name"]}</h2>
                        </div>
                        <div>
                          <h3>Rating : {teacher["teacher_rating"]}</h3>
                          <div
                            onClick={() => {
                              setTeachersInfo(teacher);
                              window.scrollTo(0, 0);
                            }}
                          >
                            View Teacher Profile
                          </div>
                        </div>

                        <div>
                          <div
                            id="BookClassBtnTeacher"
                            // onClick={async () => {
                            //   setTeacher(teacher);
                            //   if (teacher["Science"]) {
                            //     setSubjects(teacher["Science"]);
                            //     setClasses(teacher["Science-classes"]);
                            //   }
                            // }}
                            onClick={() => {
                              window.location.href =
                                "https://wa.me/+918009129336";
                            }}
                          >
                            <p> Book Class</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              <h1>Digital Literacy</h1>
              {teachersData.map((teacher, idx) => {
                if (teacher["Digital Literacy"]) {
                  return (
                    <div id={idx} className="TeachersInfoTab">
                      <div>
                        <img
                          src={teacher["teacher_profile_url"]}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div id="TeachersDetailBox">
                        <div>
                          <h2>{teacher["teacher_name"]}</h2>
                        </div>
                        <div>
                          <h3>Rating : {teacher["teacher_rating"]}</h3>
                          <div
                            onClick={() => {
                              setTeachersInfo(teacher);
                              window.scrollTo(0, 0);
                            }}
                          >
                            View Teacher Profile
                          </div>
                        </div>

                        <div>
                          <div
                            id="BookClassBtnTeacher"
                            // onClick={async () => {
                            //   setTeacher(teacher);
                            //   if (teacher["Digital Literacy"]) {
                            //     setSubjects(teacher["Digital Literacy"]);
                            //     setClasses(teacher["Digital Literacy-classes"]);
                            //   }
                            // }}
                            onClick={() => {
                              window.location.href =
                                "https://wa.me/+918009129336";
                            }}
                          >
                            <p> Book Class</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              <h1>Mathematics</h1>
              {teachersData.map((teacher, idx) => {
                if (teacher["Mathematics"]) {
                  return (
                    <div id={idx} className="TeachersInfoTab">
                      <div>
                        <img
                          src={teacher["teacher_profile_url"]}
                          alt=""
                          srcset=""
                        />
                      </div>
                      <div id="TeachersDetailBox">
                        <div>
                          <h2>{teacher["teacher_name"]}</h2>
                        </div>
                        <div>
                          <h3>Rating : {teacher["teacher_rating"]}</h3>
                          <div
                            onClick={() => {
                              setTeachersInfo(teacher);
                              window.scrollTo(0, 0);
                            }}
                          >
                            View Teacher Profile
                          </div>
                        </div>

                        <div>
                          <div
                            id="BookClassBtnTeacher"
                            // onClick={async () => {
                            //   setTeacher(teacher);
                            //   if (teacher["Mathematics"]) {
                            //     setSubjects(teacher["Mathematics"]);
                            //     setClasses(teacher["Mathematics-classes"]);
                            //   }
                            // }}
                            onClick={() => {
                              window.location.href =
                                "https://wa.me/+918009129336";
                            }}
                          >
                            <p> Book Class</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      ) : (
        <div id="BookSessionBox">
          <h2 id="BookSessionHeading">Book your session now</h2>
          <div id="BookSessionInputs">
            <div>
              <select
                className="BookSessionInput"
                value={studing_class}
                onChange={(e) => setStudingClass(e.target.value)}
              >
                {classes.map((date) => {
                  return <option value={date}>{date}</option>;
                })}
              </select>
            </div>
            {/* <div>
            <select
              className="BookSessionInput"
              value={board}
              onChange={(e) => setBoard(e.target.value)}
            >
              <option value="">Select Board</option>
              {boards.map((date) => {
                return <option value={date}>{date}</option>;
              })}
            </select>
          </div>
          <div>
            <select
              className="BookSessionInput"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="">Select State</option>
              {states.map((date) => {
                return <option value={date}>{date}</option>;
              })}
              <option value="Haryana">Haryana</option>
            </select>
          </div> */}
            <div>
              <select
                className="BookSessionInput"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              >
                {subjects.map((date) => {
                  return <option value={date}>{date}</option>;
                })}
              </select>
              <div>
                <input
                  type="number"
                  min={1}
                  className="BookSessionInput"
                  value={classNumber}
                  placeholder="Number of classes"
                  onChange={(e) => setClassNumber(e.target.value)}
                />
              </div>
            </div>
            <div>
              {100 * classNumber > 0 ? (
                <h2>Total Amount : {100 * classNumber}</h2>
              ) : (
                <h2>Total Amount : {0}</h2>
              )}
            </div>
            <div>
              <button
                id="BookSessionSubmit"
                onClick={() => handleBookSession()}
              >
                Register Session
              </button>
            </div>
          </div>
        </div>
      )}
      {teachersInfo != "" ? (
        <>
          <TeachersInfo data={teachersInfo} />
          <div
            id="CloseTeachersInfo"
            onClick={() => {
              setTeachersInfo("");
            }}
          >
            Close
          </div>
        </>
      ) : (
        <></>
      )}
      {toastmessage ? <ToastComponent message={toastmessage} /> : <></>}
    </div>
  );
};

export default BookSession;
