import React from "react";
import "./BookSession.css";

const TeachersInfo = (props) => {
  return (
    <div id="TeachersPopupInfo">
      <div>
        <h2>Teacher's Profile</h2>
        <div>
          <img src={props.data.teacher_profile_url} alt="" srcset="" />
          <h2>{props.data.teacher_name}</h2>
        </div>
        <div>
          {props.data.subjects.map((subject) => {
            return <div>{subject}</div>;
          })}
        </div>
        <div>
          <h2>Overview</h2>
          <p>{props.data.overview}</p>
        </div>
      </div>
      <div id="YTVideo">
        <iframe
          width="420"
          height="315"
          src={`https://www.youtube.com/embed/${props.data.youtube_video_id}`}
        ></iframe>
      </div>
    </div>
  );
};

export default TeachersInfo;
