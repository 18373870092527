import React, { useState, useEffect } from "react";
import "./ImagePopup.css";

const ImagePopup = ({ imageUrl, altText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
      setIsVisible(true);
    }, 2000); // 2 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const closePopup = () => {
    setIsVisible(false);
    setTimeout(() => setIsOpen(false), 300);
  };

  return (
    <div>
      {isOpen && (
        <div
          className={`popup-overlay ${isVisible ? "fade-in" : "fade-out"}`}
          onClick={closePopup}
        >
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={closePopup}>
              &times;
            </button>
            <img
              id="PopupImg"
              src="https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/images%2Fpopup.PNG?alt=media&token=3ba95964-60cf-4e8f-928c-0b8710499b9a"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagePopup;
