import React, { useEffect, useState } from "react";
import "./Courses.css";
import { Link } from "react-router-dom";
import CourseTab from "./CourseTab";

// const coursesDataOffline = [
//   {
//     main_heading: "Competitive courses",
//     id: "Competitive",
//     courses: [
//       {
//         price: 1000,
//         course_id: "GENZCHS25",
//         heading: "GEN-Z CHS 2025",
//         batch: "2024-2025",
//         sub_heading: "FULL COURSE",
//         type: "ONLINE",
//         class: "FOR CLASS 9th",
//         short_discription: "START YOUR JOURNEY FROM 1000 RS/Month",
//         pic: chs,
//       },
//       {
//         price: 799,
//         course_id: "GENZFOU25",
//         heading: "GEN-Z FOUNDATION ",
//         batch: "2024-2025",
//         sub_heading: "FULL COURSE",
//         type: "ONLINE",
//         class: "FOR CLASS 6th - 10th",
//         short_discription: "START YOUR JOURNEY FROM 799 RS/Month",
//         pic: foundation,
//       },
//       {
//         price: 1000,
//         course_id: "GENZSS25",
//         heading: "GEN-Z SANIK SCHOOL ",
//         batch: "2024-2025",
//         sub_heading: "FULL COURSE",
//         type: "ONLINE",
//         class: "FOR CLASS 6th - 9th",
//         short_discription: "START YOUR JOURNEY FROM 1000 RS/Month",
//         pic: sanik,
//       },
//       {
//         price: 599,
//         course_id: "GENZNAVOS25",
//         heading: "GEN-Z NAVODYA ",
//         batch: "2024-2025",
//         sub_heading: "FULL COURSE",
//         type: "ONLINE",
//         class: "FOR CLASS 6th",
//         short_discription: "START YOUR JOURNEY FROM 599 RS/Month",
//         pic: navodya,
//       },
//     ],
//   },
//   {
//     main_heading: "Academic courses",
//     id: "Academic",
//     courses: [
//       {
//         course_id: "GENZMT1025",
//         price: 1000,
//         heading: "GEN-Z MATHEMATICS ",
//         batch: "2024-2025",
//         sub_heading: "FULL COURSE",
//         type: "ONLINE",
//         class: "CBSE BOARD 10th",
//         short_discription: "START YOUR JOURNEY FROM 1000 RS/Month",
//       },
//       {
//         price: 1000,
//         course_id: "GENZMT925",
//         heading: "GEN-Z MATHEMATICS ",
//         batch: "2024-2025",
//         sub_heading: "FULL COURSE",
//         type: "ONLINE",
//         class: "CBSE BOARD 9th",
//         short_discription: "START YOUR JOURNEY FROM 1000 RS/Month",
//       },
//       {
//         price: 1000,
//         course_id: "GENZPC1025",
//         heading: "GEN-Z PHYSIC + CHEMISTRY ",
//         batch: "2024-2025",
//         sub_heading: "FULL COURSE",
//         type: "ONLINE",
//         class: "CBSE BOARD 10th",
//         short_discription: "START YOUR JOURNEY FROM 1000 RS/Month",
//       },
//       {
//         price: 1000,
//         course_id: "GENZPC925",
//         heading: "GEN-Z PHYSIC + CHEMISTRY ",
//         batch: "2024-2025",
//         sub_heading: "FULL COURSE",
//         type: "ONLINE",
//         class: "CBSE BOARD 9th",
//         short_discription: "START YOUR JOURNEY FROM 1000 RS/Month",
//       },
//     ],
//   },
//   {
//     main_heading: "Language courses",
//     id: "Language",
//     courses: [
//       {
//         price: 2999,
//         course_id: "GENZSPOK25",
//         heading: "GEN-Z SPOKEN ",
//         batch: "2024-2025",
//         sub_heading: "FULL COURSE",
//         type: "ONLINE",
//         class: "ANYONE CAN JOIN",
//         short_discription: "START YOUR JOURNEY FROM 2999 RS/Month",
//         pic: spoken,
//       },
//     ],
//   },
//   {
//     main_heading: "Campus courses",
//     id: "Campus",
//     courses: [
//       {
//         price: 99,
//         course_id: "GENZPLACE25",
//         heading: "GEN-Z CAMPUS PLACEMENT ",
//         batch: "2024-2025",
//         sub_heading: "GUIDANCE @99 RS",
//         type: "ONLINE",
//         class: "FOR COLLAGE STUDENTS",
//         short_discription: "IIT/IIM/Industry leaders/Core branche",
//         pic: campus,
//       },
//     ],
//   },
// ];

const Courses = () => {
  const [coursesData, setCourseData] = useState([]);
  const fetchCourses = async (type) => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    // REQUEST SIGNUP

    fetch(process.env.REACT_APP_BACKEND_URL + "/getcourses", {
      method: "GET",
      headers: headers,
      body: JSON.stringify(),
      // credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCourseData(data.data);
      });
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <div>
      {/* <div id="CourseLinkBox">
        <div>
          <h4>
            <Link>Home</Link> / <Link>Courses</Link>
          </h4>
        </div>
        <h2 id="CourseLinkH">ALL COURSES</h2>
      </div>*/}
      <div id="CoursesNav">
        <ul>
          <li>
            <Link to="#">
              <h4 className="ActiveNav">All Batches </h4>
            </Link>{" "}
          </li>
          {coursesData.map((course, idx) => {
            return (
              <li id={idx}>
                <a href={`#${course.id}`}>
                  <h4>{course.main_heading}</h4>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div id="CoursesBox">
        {coursesData.map((course) => {
          return (
            <div>
              <h2>{course.main_heading}</h2>
              <div id="Courses">
                {course.courses.map((coursedata) => {
                  return <CourseTab course={coursedata} />;
                })}
              </div>
              ;
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Courses;
