import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BookDiscription = () => {
  const { book_id } = useParams();
  const [isBookFetched, setIBF] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [location, setLocation] = useState({
    longitude: 0.0,
    latitude: 0.0,
  });

  const [book_data, setBookData] = useState([]);
  const [isPreviewClicked, setIPC] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const getLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setLocation({
          longitude: pos.coords.latitude,
          latitude: pos.coords.longitude,
        });
      });
    } else {
      alert = "Please enable location services to get delivery time.";
    }
  };

  const FillStarsByRating = (book_rating) => {
    let rating = Math.round(book_rating * 2) / 2;
    console.log(rating);
    let ratingDiv = document.getElementById("BookRating");
    console.log(Boolean(ratingDiv));
    if (ratingDiv) {
      if (rating === 0.5) {
        ratingDiv.classList.add("halfStar");
      } else if (rating === 1) {
        ratingDiv.classList.add("oneStar");
      } else if (rating === 1.5) {
        ratingDiv.classList.add("oneAndHalfStar");
      } else if (rating === 2) {
        ratingDiv.classList.add("twoStar");
      } else if (rating === 2.5) {
        ratingDiv.classList.add("twoAndHalfStar");
      } else if (rating === 3) {
        ratingDiv.classList.add("threeStar");
      } else if (rating === 3.5) {
        ratingDiv.classList.add("threeAndHalfStar");
      } else if (rating === 4) {
        ratingDiv.classList.add("fourStar");
      } else if (rating === 4.5) {
        ratingDiv.classList.add("fourAndHalfStar");
      } else if (rating === 5) {
        ratingDiv.classList.add("fiveStar");
      }
    }
  };

  const getProductDetails = async () => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // REQUEST SIGNUP

    const data = await fetch(
      process.env.REACT_APP_BACKEND_URL + `/get-book-details`,
      {
        method: "POST",
        headers: headers,
        mode: "cors",
        body: JSON.stringify({
          location: location,
          book_id: book_id,
        }),
        credentials: "include",
      }
    ).then((res) => {
      return res.json();
    });
    setBookData(data.book);
    return data.book;
  };

  // console.log(data);
  useEffect(() => {
    getLocation();
    getProductDetails().then((res) => {
      FillStarsByRating(res.book_rating);
      setPreviewImage(res.book_img[0]);
      setIBF(true);
    });
  }, []);

  if (isBookFetched) {
    return (
      <div id="BookDiscriptionPage">
        <div className="BookDiscriptionImage">
          <img src={previewImage} alt={book_data.book_title} />
          <div>
            {book_data.book_img.map((image, idx) => {
              return (
                <img
                  src={image}
                  alt="Book Preview"
                  className="bookPreviewImages"
                  onClick={() => {
                    setPreviewImage(image);
                    setIPC(true);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="BookDiscriptionContent">
          <h2>{book_data.book_title}</h2>
          <p>{book_data.book_author} </p>
          <div className="BookRatingDiv">
            <span id="BookRating"> ★★★★★</span>
          </div>
          <p>{book_data.book_type}</p>
          <div className="BookContentDisciption">
            <p>{book_data.book_details.book_discription}</p>
          </div>{" "}
          <div className="BookTypesBox">
            <div>
              <p>Reading Age</p>
              <h5>{book_data.book_details.reading_age}</h5>
            </div>
            <div>
              <p>Language</p>
              <h5>{book_data.book_details.book_language}</h5>
            </div>
            <div>
              <p>Print Length</p>
              <h5>{book_data.book_details.book_pages}</h5>
            </div>
            <div>
              <p>Dimentions</p>
              <h5>
                {book_data.book_details.book_dimentions.height} x{" "}
                {book_data.book_details.book_dimentions.width}{" "}
                {book_data.book_details.book_dimentions.unit}
              </h5>
            </div>
          </div>
        </div>
        <div className="BookDetails">
          <div className="BookPricing">
            <h4>Rs {book_data.book_discounted_price}</h4>
            <div>MRP Rs {book_data.book_orignal_price}</div>
          </div>
          <p>The customer will be notified about delivery charges applied to their order based on location of delivery.</p>
          <h3 className="BookDeliveryTime">
            Will deliver to you by{" "}
            {book_data.book_delivery_details.book_delivery_time}
          </h3>
          <h3 className="BookDeliveryLocation">
            Delivering to {/* Add dynamic location */}
            {/* {book_data.book_delivery_details.book_delivery_location} - Update */}
            your location
          </h3>
          <h2 className="BookDeliveryInstock">In stock</h2>
          <p className="BookShippingDetails">
            Ships from : {book_data.book_author}
          </p>
          <div className="BookBuyingDetails">
            <div className="BookQuantityDetails">
              <p>Quantity</p>
              <input
                type="number"
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
            </div>

            <button id="BookAddToCart">Add to cart</button>
            <button
              id="BookBuyNow"
              onClick={() => {
                window.location.href = "https://wa.me/+918009129336";
              }}
            >
              Buy now
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default BookDiscription;
