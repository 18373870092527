import React from "react";
import "./StudyMaterial.css";
import PDFViewer from "../components/PdfViewer/PdfViewer";
import PDFViewer2 from "../components/PdfViewer/PdfViewer2";

const StudyMaterial = () => (
  <div id="StudyMaterialBox">
    <h1>Study material</h1>
    <div id="StudyMaterialTabs">
      <div>
        <PDFViewer
          url={
            "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/StudyMaterial%2FClass-IX_(Set-3).pdf?alt=media&token=f92b7698-2b5f-4292-bda8-b0e6a811a4f2"
          }
        />
      </div>
      <div>
        <PDFViewer
          url={
            "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/StudyMaterial%2FClass-IX_(Set-2).pdf?alt=media&token=924c9577-d5ce-4db5-be32-127e0db722f2"
          }
        />
      </div>
      <div>
        <PDFViewer2
          url={
            "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/StudyMaterial%2FAcid%20Base%20Salt.pdf?alt=media&token=e7c11413-9c3c-40cc-a8fa-e8232713f1fc"
          }
        />
      </div>
      <div>
        <PDFViewer2
          url={
            "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/StudyMaterial%2FTissues.pdf?alt=media&token=5059ce4a-b6dc-4483-92e2-5e8c2b7abc35"
          }
        />
      </div>
      <div>
        <PDFViewer2
          url={
            "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/StudyMaterial%2Fchapter%205.pdf?alt=media&token=a13de25c-0aeb-4234-8d5e-add709b9ed07"
          }
        />
      </div>

      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vSh6xV2Jw0cjAP3qeS_L5CD0UxHCLctJ9dHop9l0yW5Ao31hM8D2uM3M1yXqMDX9HZZlrlnoLYS88qq/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vS2rj1z6c90QuYN34mytYJKWbD7Bug52gXJ79iEodKR1S6h3rdTnu_5libPHjlBRQ/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="329"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vROceJjR167K44-0agigU0Z7ythmG_lcHh7_sXAN-kUJAunjJhfebmqrag8IXwNbw/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="329"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>

      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vTWfzY8FIqIfm8YAkLPtADOviYitSwa2TX6EFfF1Z1dp-zYrGIh8Z7KRfO6lnGkxOR5VG89m-idvvUQ/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="384"
          height="509"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vTE_UTcFJC_-tc0kBJNkJr839rjs09SoaxjKCbUkNsrtwQPPyzBcXVjwP6MFcHj-w/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vQgyhaL3C9Tj08quUURILXQ_Emzgv2q4VwtQB8FdmrP82IFt2jXPlAIsJHcxAqaL-2xHELQRVkBL71P/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="384"
          height="509"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vTA-bBHUV_TatNBWubqGOI0LDuamGTBrhGyfE5t2SzQtAKocvQHkQehKkXFA0bDUA/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vQDI24DCnhtOblxGZ8wzqCxdK0eBpf4FFZ0lWMTdpvDkIMC6A12mBAWse5mRSTNYx9OhJ2wq2uy5fd4/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="384"
          height="509"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vSvmfCU7LF_GboV9hG7Cx4dRhRtlTTPejNcKB0W4aznTYky0uOuCQRRKUL1Tbv8kCDSouuu90WbBL1L/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vRfvKQKxoKPsXqR-WUbfucjNCeWhur_tYV-krl-jqLMpEdvAimtXl_XRR3E4oP7thSO8GUe4ABrS6m5/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="384"
          height="509"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vQ15rJEX4n5L71VkjLQHwBHoWyZSZKhzEajDqmic_9LmSUUIEfijemh2rNxXfoaNA/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="389"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vTRTH2WrJNIlGaJ7sKgC1NYHdS-lc1X3rMQC3xPMl1-VUahN6WIMnWstAYeDHteGKHdX4R-_tmRJxSN/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="384"
          height="509"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vT3OfyeNwIpvSLbK3XD_LNp3mkOElgEu8tCBzglydanGfwTc7eRyU_X4hZrhKd3SQ/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="329"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      {/*<div> */}
      {/* <iframe
        src="https://docs.google.com/presentation/d/e/2PACX-1vREwOcdyJd1YEkdmbtfdQy500WGUzaAQsrdymDiTYk2yE-3tWPBjnV7JSKJuw0Ikg/embed?start=false&loop=false&delayms=3000"
        frameborder="0"
        width="384"
        height="509"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      ></iframe> */}
      {/* </div> */}

      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vRCnJ2IGci4CBC6VqPnIOQWel7qnKva1DOCcuV0UYsfZpRNroIarVaNiAOTzs5p04SiOlXxyK2HnK07/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      {/*  */}
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vSbrM2yEeqew5i78qqmoawh6LkGJUODHL-9R0n_KOK_pQoNf9Rbrf9p-d7KrDW0_9vWf90nOJgsYbhg/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vTQVj3uxg3kJzAXyTbvl4uBCK_XlHF329JZd_FRSsaUf_Bp9cg0LujZeM8kseEjFxKHfRilQaYWvCLd/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>

      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vSIEKYz7sOJvnjKoRgXhz7AfgrqXXuQNm-fhHOlhei_CDxGwjlGeSoHpnkO_P9WL5dTn83mfxYYHeOm/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>

      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vThfY4xI4w9NyBTX56qFBhmK_jk7wTA456SBl6-VxTDoJP4GEu65vbtquBnfNGkD8GEvP9AoK3EVLjD/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vRI1l24pNSPKOG5cmGTH7gxfjCbodvkhKVM53AMPWAq8xRaMWd-4ER4ROMuW_6YqYznrzg-mSCyKaQA/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      {/* <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vSELxfrdOgSohbkdZHQnW_B9-lF1eIIDGKDXm_MO6ZaAssnzyhmffzgPyJkAA5rG9ZJHWQUsRmhuaC2/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="384"
          height="509"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div> */}
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vSWtgjoUM3qEnnWE0zAu42IU_vq4Xd_GdGscL5U1HAt9eUhFAbvEXd0ep2OWXo-fvfhVrD7zkVsqvGh/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vTDy2rFxjMzb2Yq_JsehqahR_iSt7dU2-HfEfT--7HaDYsdEwynepldhOHYiLEKkhcb4SOqaQNPShWN/embed?start=false&loop=false&delayms=3000"
          frameborder="0"
          width="480"
          height="299"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
        ></iframe>
      </div>
      {/*  */}
    </div>
  </div>
);

export default StudyMaterial;
