import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      <p>
        This is the official page of VOE ACADEMY, where you can share all your
        queries, feedback, complaints, or any concern you may have about our
        centers, courses, and programs.
      </p>
      <p>
        VOE Academy, India's leading student-oriented Edtech organization
        working for rural India, is here to help all the students to solve their
        grievances. We're here to help you. We aim to solve all the queries
        within 3 days. You can call on VOE Academy official Contact No.{" "}
        <a href="tel:06284518607">06284518607</a>, 24 x 7. If the phone is busy,
        we're resolving someone else's queries. We request you to contact us
        again after 5 minutes so that we can address your query or concern you
        may have regarding lectures or course material. You may notify us in the
        "contact us" section only. Your suggestions will help us improve and
        give you and all the children a bright future.
      </p>
      <p>
        In case of any grievance, don't hesitate to get in touch with us on VOE
        Academy official contact number <a href="tel:6284518607">6284518607</a>.
        Or you can write to us at{" "}
        <a href="mailto:voeacademy@gmail.com">voeacademy@gmail.com</a>.
      </p>
      <address>
        <strong>VOE ACADEMY</strong>
        <br />
        Panchkula Haryana
        <br />
        242 3rd floor
        <br />
        Sector 19 Old Housing Board, Panchkula,
        <br />
        Haryana, 134113
      </address>
      <div className="map-container">
        <div id="embed-ded-map-canvas">
          <iframe
            title="VOE Academy Location"
            src="https://www.google.com/maps/embed/v1/place?q=voe+academy&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            style={{ height: "500px", width: "100%", border: 0 }}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>{" "}
    </div>
  );
};

export default Contact;
