import React, { useState } from "react";
import "./Toast.css";

function ToastComponent(props) {
  const [showToast, setShowToast] = useState(false);

  const launchToast = () => {
    if (props.message) {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 1900);
    }
  };
  useState(() => {
    launchToast();
  }, []);

  return (
    <div>
      {showToast && (
        <div id="toast" className="show">
          <div id="desc">{props.message}</div>
        </div>
      )}
    </div>
  );
}

export default ToastComponent;
