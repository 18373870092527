import React, { useEffect, useState } from "react";
import "./Courses.css";
import LazyImage from "../Lazyimage/LazyImage";
import logo from "./../../images/logo.jpg";
import ToastComponent from "../Toast/Toast";

const CourseTab = (props) => {
  const [toastmessage, setToastmessage] = useState(false);
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const handleBuy = async (course_id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razropay failed to load!!");
      return;
    }

    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "https://www.voeacademy.com");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    // REQUEST SIGNUP

    const data = await fetch(process.env.REACT_APP_BACKEND_URL + "/buycourse", {
      method: "post",
      headers: headers,
      body: JSON.stringify({
        course_id: course_id,
      }),
      credentials: "include",
    }).then((res) => {
      return res.json();
    });

    if (data.message === "PLEASE LOGIN AGAIN") {
      setToastmessage("PLEASE LOGIN FIRST");
      setTimeout(() => setToastmessage(false), 1900);
    } else {
      const options = {
        key: process.env.REACT_APP_RAZORPAYAPI, // Enter the Key ID generated from the Dashboard
        amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "VOE Academy",
        description: "Test Transaction",
        image:
          "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/images%2Flogo.jpg?alt=media&token=e1860acb-c4c3-424e-a564-b069478c6eda",
        order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          await fetch(process.env.REACT_APP_BACKEND_URL + "/confirm-pay", {
            method: "post",
            headers: headers,
            body: JSON.stringify({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            }),
            credentials: "include",
          }).then((res) => {
            return res.json();
          });
        },
        notes: {
          address: "Sector 19,Panchkula,Haryana",
        },
        theme: {
          color: "#332cf2",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };

  const Link = () => {
    if (props.course.blog_link) {
      return (
        <>
          <h5 className="buttonsCourses">
            <a href={props.course.blog_link}>Explore</a>
          </h5>
          <div id="CourseBanner5Line"></div>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div id={props.course.id} className="course">
      <div id="Courses">
        <div id="MainCourseBanner">
          <div id="CourseBanner1">
            <h4>{props.course.heading}</h4>
            <h5 id="CourseSubheading">{props.course.sub_heading}</h5>
          </div>
          <div id="CoursePic">
            <LazyImage
              key={props.course.id}
              src={props.course.pic}
              className="lazy-image"
              id="CoursePicMain"
            />
          </div>
          <div id="CourseBanner2">
            <h5>{props.course.batch}</h5>
            <div id="CourseBanner2Line"></div>
            <h5>{props.course.type}</h5>
          </div>
          <div id="CourseBanner3">
            <h5>{props.course.class}</h5>
            <h5>{props.course.short_discription}</h5>
          </div>
          <div id="CourseBanner5">
            <Link />
            {/* <h5
              onClick={() => handleBuy(props.course.course_id)}
              className="buttonsCourses"
            > */}
            <h5
              onClick={() =>
                (window.location.href = "https://wa.me/918009129336")
              }
              className="buttonsCourses"
            >
              Buy now
            </h5>
          </div>
        </div>
      </div>
      {toastmessage ? <ToastComponent message={toastmessage} /> : <></>}
    </div>
  );
};

export default CourseTab;
