import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./QuerySolution.css";
import Faq from "./../Faq/Faq";

const QuerySolution = () => {
  const { id } = useParams();
  const [questionData, setQuesData] = useState("");
  const handleQueryResults = async () => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // REQUEST SIGNUP

    await fetch(process.env.REACT_APP_BACKEND_URL + "/getQueryResult", {
      method: "POST",
      headers: headers,
      mode: "cors",
      body: JSON.stringify({
        query_id: id,
      }),
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setQuesData(data);
      });
  };
  useEffect(() => {
    handleQueryResults();
  }, []);

  return (
    <div id="QueryPage">
      <div id="QueryBox">
        <div>
          <h2>Question</h2>
          <p>{questionData.question}</p>
        </div>
        <div>
          <h2>Answer</h2>
          <p>{questionData.answer}</p>
        </div>
      </div>
    </div>
  );
};

export default QuerySolution;
