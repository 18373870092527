import React from "react";
import "./../Blogs.css";
import Sanikpdf from "./Sanik.pdf";

const Sanik = () => {
  return (
    <div id="Blog">
      <header className="blog-header">
        <h1>All India Sainik Schools Entrance Examination (AISSEE) 2023</h1>
      </header>
      <div>
        <main className="main-content">
          <section className="exam-details">
            <h2>Exam Details</h2>
            <ul>
              <li>
                <strong>Name:</strong> All India Sainik Schools Entrance
                Examination (AISSEE) 2023
              </li>
              <li>
                <strong>Date:</strong> Already happened on January 8th, 2023
              </li>
              <li>
                <strong>Admissions:</strong>
              </li>
              <ul>
                <li>
                  <strong>Schools:</strong> Sainik Schools and Approved New
                  Sainik Schools
                </li>
                <li>
                  <strong>Grades:</strong> Class VI and IX for Sainik Schools,
                  Class VI only for New Sainik Schools
                </li>
                <li>
                  <strong>Selection:</strong> Based on AISSEE 2023 score,
                  medical fitness, and document verification
                </li>
              </ul>
            </ul>
          </section>
          <section className="eligibility">
            <h2>Eligibility</h2>
            <ul>
              <li>
                <strong>Age:</strong> Between 10-12 years for Class VI (as of
                March 31, 2023)
              </li>
              <li>
                <strong>Gender:</strong> Boys for Sainik Schools (check specific
                school websites for girls in New Sainik Schools)
              </li>
            </ul>
          </section>
          <section className="key-differences">
            <h2>
              Key Differences between Sainik Schools and New Sainik Schools
            </h2>
            <ul>
              <li>
                <strong>Admission process:</strong> Separate streams for Sainik
                Schools and New Sainik Schools (40% and 60% quota) within
                AISSEE.
              </li>
              <li>
                <strong>Eligibility:</strong> Domicile and category reservations
                apply for Sainik Schools, not for New Sainik Schools (40%
                quota).
              </li>
              <li>
                <strong>Selection:</strong> Sainik Schools consider domicile and
                category, New Sainik Schools (40% quota) do not.
              </li>
            </ul>
          </section>
          <section className="additional-points">
            <h2>Additional Points</h2>
            <ul>
              <li>
                Official website -{" "}
                <a href="https://aissee.nta.nic.in/">
                  https://aissee.nta.nic.in/
                </a>
              </li>
              <li>
                The document advises checking individual school websites for
                their admission policies and fee structures.
              </li>
              <li>
                E-counselling for New Sainik Schools happens after Sainik School
                admissions are complete.
              </li>
            </ul>
          </section>
        </main>
      </div>
      <footer className="blog-footer">
        <a href="#" download={Sanikpdf}>
          Download pdf{" "}
        </a>
      </footer>
    </div>
  );
};

export default Sanik;
