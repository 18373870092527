import React, { useState, useEffect } from "react";
import "./YoutubePreview.css"

const YouTube = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex]);

  function plusSlides(n) {
    setSlideIndex((prevIndex) => (prevIndex + n) % 4);
  }

  function currentSlide(n) {
    setSlideIndex(n - 1);
  }

  function showSlides(n) {
    const slides = document.getElementsByClassName("yt-mySlides");
    if (n >= slides.length) {
      setSlideIndex(0);
      return;
    }
    if (n < 0) {
      setSlideIndex(slides.length - 1);
      return;
    }
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
 
    slides[n].style.display = "block";
  }

  return (
    <div>
      <div className="yt-slideshow-container">
        <h1>Student Reviews</h1>
        <div className="yt-mySlides">
        <iframe width="560" height="315" src="https://youtube.com/embed/_wtD6PCL2To?feature=shared" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>  </div>

          <div className="yt-mySlides">
        <iframe width="560" height="315" src="https://youtube.com/embed/zOZYc6z8VwQ?feature=shared" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>  </div>
          
          <div className="yt-mySlides">
        <iframe width="560" height="315" src="https://youtube.com/embed/ezLap7KL_QE?si=Ndz7nmTuoiBY_eCA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>  </div>
       
        <div className="yt-mySlides">
        <iframe width="560" height="315" src="https://youtube.com/embed/fxTJn1EyPGM?feature=shared" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>  </div>
       
      </div>
      <div className="YTcontrols">

      <button className="prev" onClick={() => plusSlides(-1)}>
          &#10094;
        </button>
        <button className="next" onClick={() => plusSlides(1)}>
          &#10095;
        </button>
        </div>
     
    </div>
  );
};

export default YouTube;
