import React, { useEffect, useState } from "react";
import "./Comment.css";
import ToastComponent from "../Toast/Toast";

const Comment = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [toast, setToastmessage] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  const setUserDataCookie = async () => {
    document.cookie = `comment_data = ${name}:${email}; SameSite = None; Secure`;
  };
  const getUserDataCookie = async () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("comment_data="))
      ?.split("=")[1];
    console.log(cookieValue);
    if (cookieValue) {
      let data = cookieValue.split(":");
      setName(data[0]);
      setEmail(data[1]);
    }
  };
  const handlePostComment = async (e) => {
    e.preventDefault();
    if (!name || !email || !comment) {
      setMessage("Please enter all input feilds");
    }
    if (checkbox) {
      setUserDataCookie();
    }
    // Function to send data to backend
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // REQUEST SIGNUP
    await fetch(process.env.REACT_APP_BACKEND_URL + "/addcomment", {
      method: "POST",
      headers: headers,
      mode: "cors",
      body: JSON.stringify({
        name: name,
        email: email,
        comment: comment,
      }),
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setToastmessage(data.message);
        setTimeout(() => setToastmessage(false), 1900);
      });
  };

  useEffect(() => {
    getUserDataCookie();
  }, []);

  return (
    <div>
      <div>
        <form action="" id="CommentBox">
          <div>
            <h1>Leave a Reply</h1>
            <p>
              Your email address will not be published (required fields are
              marked)
            </p>
          </div>
          <div id="CommentFeilds">
            <div>
              <textarea
                name="comment"
                placeholder="Comment"
                rows="6"
                value={comment}
                id="CommentZone"
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </div>
            <div>
              <div>
                <input
                  className="textFeilds"
                  type="text"
                  value={name}
                  name="name"
                  placeholder="*Name"
                  id="CommentName"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div>
                <input
                  className="textFeilds"
                  type="email"
                  name="email"
                  value={email}
                  placeholder="*Email"
                  id="CommentEmail"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <input
                type="checkbox"
                name="saveemail"
                id="Checkbox"
                onClick={() => {
                  if (checkbox) {
                    setCheckbox(false);
                  } else {
                    setCheckbox(true);
                  }
                }}
              />
              <label htmlFor="saveemail" id="CheckboxText">
                Save my name and email in the browser for next time I comment.
              </label>
            </div>
          </div>
          <p id="ErrorMessage">{message}</p>
          <div>
            <button id="PostCommentBtn" onClick={handlePostComment}>
              POST COMMENT
            </button>
          </div>
        </form>
      </div>
      {toast ? <ToastComponent message={toast} /> : <></>}
    </div>
  );
};

export default Comment;
