import React, { useState } from "react";
import Typewriter from "typewriter-effect";
import udyam from "./../images/Main/udyam.PNG";
import startupindia from "./../images/Main/startupindia.PNG";
import Center from "./../images/Main/class.svg";
import Doubt from "./../images/Main/doubt.svg";
import Live from "./../images/Main/live.svg";
import Paper from "./../images/Main/paper.svg";
import "./Home.css";
import { Link } from "react-router-dom";
import Faq from "../Faq/Faq";
import Signup from "../Auth/Signup/Signup";
import playstore from "./../images/Main/PlayStore.png";
import Testimonial from "../components/Testimonal/Testimonal";
import Comment from "../components/Comment/Comment";
import Count from "./Count";
import HeroCarousel from "../components/HeroCarousel/HeroCarousel";
import ImagePopup from "../components/ImagePopup/Image";
import Youtube from "../components/YoutubePreview/YoutubePreview";

const counters = [
  {
    id: "001",
    number: "843",
    label: "YouTube",
    duration: "2",
  },
  {
    id: "002",
    label: "LinkedIn",
    number: "872",
    duration: "2",
  },
  {
    id: "003",
    label: "Students Enrolled",
    number: "1000",
    duration: "2",
  },
];

const Home = () => {
  // const [signinbool, setsigninbool] = useState(false);
  const [signinbool, setsigninbool] = useState(false);
  const isSignedIn = async () => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // REQUEST SIGNUP

    await fetch(process.env.REACT_APP_BACKEND_URL + "/issignedin", {
      method: "get",
      headers: headers,
      mode: "cors",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setsigninbool(data.signinbool);
      });
  };

  useState(() => {
    isSignedIn();
  }, []);
  return (
    <>
      {signinbool ? (
        <div>
          <div id="ResultBox">
            <HeroCarousel />
          </div>
          <section id="Hero">
            <div id="HeroBox">
              <a href="/booksession" id="HeroBookSession">
                Book your demo session now
              </a>
              <h1>
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .changeDelay(70)
                      .typeString("Alok Sir, What is VOE ?")
                      .start();
                  }}
                />
              </h1>
              <h2>
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .pauseFor(2500)

                      .changeDelay(70)
                      .typeString(
                        "VOE is India's leading Edtech company that is working for Rural India "
                      )
                      .start();
                  }}
                />
              </h2>
            </div>
          </section>

          <section className="info">
            <ul className="infoBox ">
              <li className="infoBoxTab">
                <div className="DailLiveBox">
                  <img src={Live} className="DailyLive"></img>
                </div>
                <p>Interactive Classes</p>
              </li>
              <li className="infoBoxTab">
                <div className="DailLiveBox">
                  <img src={Paper} className="DailyLive"></img>
                </div>
                <p>Test, Sample Papers, Notes</p>
              </li>
            </ul>
            <ul className="infoBox">
              <li className="infoBoxTab">
                <div className="DailLiveBox">
                  <img src={Doubt} className="DailyLive"></img>
                </div>
                <p> Doubt solving</p>
              </li>
              <li className="infoBoxTab">
                <div className="DailLiveBox">
                  <img src={Center} className="DailyLive"></img>
                </div>
                <p> Offline centers</p>
              </li>
            </ul>
          </section>
          <section id="ExamCate">
            <div id="ExamBox">
              <h1>Online Presence</h1>
              <h2>We have touched 3.5 lakh student views on YouTube</h2>

              <ul className="OnlinePresenceBox ">
                <Count
                  label={counters[0].label}
                  number={counters[0].number}
                  duration={counters[0].duration}
                />
                <Count
                  label={counters[1].label}
                  number={counters[1].number}
                  duration={counters[1].duration}
                />
                <Count
                  label={counters[2].label}
                  number={counters[2].number}
                  duration={counters[2].duration}
                />
              </ul>

              <h2>50K impressions on LinkedIn</h2>
            </div>
          </section>
          <section>
            <div id="OfficalDocsPage">
              <h1>Official documents</h1>
              <div id="OfficialDocs">
                <img src={startupindia} alt="" srcset="" />
              </div>
            </div>
          </section>
          <section>
            <Faq />
          </section>
          <section>
            <div>
              <h1 id="HomeMapHeading">States already covered</h1>
            </div>
            <div id="HomeMapBox">
              <img
                id="HomeMap"
                src="https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/images%2FStatesCovered.png?alt=media&token=ab4b06c6-249e-40f1-ab1b-d40f277f9ce1"
                alt=""
                srcset=""
              />
            </div>
          </section>
          <section>
            <div id="WhoWeAreBox">
              <div>
                <h1 id="WhoWeAreHeading">Who we are ?</h1>
              </div>
              <div>
                <div className="ag-format-container">
                  <div className="ag-courses_box">
                    <div className="ag-courses_item">
                      <a href="#" className="ag-courses-item_link">
                        <div className="ag-courses-item_bg"></div>

                        <div className="ag-courses-item_title">ONE</div>

                        <div className="ag-courses-item_date-box">
                          <span className="ag-courses-item_date">
                            Edtech startup company under DPIIT
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="ag-courses_item">
                      <a href="#" className="ag-courses-item_link">
                        <div className="ag-courses-item_bg"></div>

                        <div className="ag-courses-item_title">TWO</div>

                        <div className="ag-courses-item_date-box">
                          <span className="ag-courses-item_date">
                            MSME certified company
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="ag-courses_item">
                      <a href="#" className="ag-courses-item_link">
                        <div className="ag-courses-item_bg"></div>

                        <div className="ag-courses-item_title">THREE</div>

                        <div className="ag-courses-item_date-box">
                          <span className="ag-courses-item_date">
                            GOVT registered company
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <Youtube />
          </section>
          <section>
            <Testimonial />
          </section>

          <section>
            <Comment />
          </section>

          <Link to={"https://wa.me/+918009129336"} id="WhatsappUS">
            <svg height="100%" version="1.1" viewBox="0 0 512 512" width="100%">
              <path d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-386.892,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Zm-58.673,127.703c-33.842,-33.881 -78.847,-52.548 -126.798,-52.568c-98.799,0 -179.21,80.405 -179.249,179.234c-0.013,31.593 8.241,62.428 23.927,89.612l-25.429,92.884l95.021,-24.925c26.181,14.28 55.659,21.807 85.658,21.816l0.074,0c98.789,0 179.206,-80.413 179.247,-179.243c0.018,-47.895 -18.61,-92.93 -52.451,-126.81Zm-126.797,275.782l-0.06,0c-26.734,-0.01 -52.954,-7.193 -75.828,-20.767l-5.441,-3.229l-56.386,14.792l15.05,-54.977l-3.542,-5.637c-14.913,-23.72 -22.791,-51.136 -22.779,-79.287c0.033,-82.142 66.867,-148.971 149.046,-148.971c39.793,0.014 77.199,15.531 105.329,43.692c28.128,28.16 43.609,65.592 43.594,105.4c-0.034,82.149 -66.866,148.983 -148.983,148.984Zm81.721,-111.581c-4.479,-2.242 -26.499,-13.075 -30.604,-14.571c-4.105,-1.495 -7.091,-2.241 -10.077,2.241c-2.986,4.483 -11.569,14.572 -14.182,17.562c-2.612,2.988 -5.225,3.364 -9.703,1.12c-4.479,-2.241 -18.91,-6.97 -36.017,-22.23c-13.314,-11.876 -22.304,-26.542 -24.916,-31.026c-2.612,-4.484 -0.279,-6.908 1.963,-9.14c2.016,-2.007 4.48,-5.232 6.719,-7.847c2.24,-2.615 2.986,-4.484 4.479,-7.472c1.493,-2.99 0.747,-5.604 -0.374,-7.846c-1.119,-2.241 -10.077,-24.288 -13.809,-33.256c-3.635,-8.733 -7.327,-7.55 -10.077,-7.688c-2.609,-0.13 -5.598,-0.158 -8.583,-0.158c-2.986,0 -7.839,1.121 -11.944,5.604c-4.105,4.484 -15.675,15.32 -15.675,37.364c0,22.046 16.048,43.342 18.287,46.332c2.24,2.99 31.582,48.227 76.511,67.627c10.685,4.615 19.028,7.371 25.533,9.434c10.728,3.41 20.492,2.929 28.209,1.775c8.605,-1.285 26.499,-10.833 30.231,-21.295c3.732,-10.464 3.732,-19.431 2.612,-21.298c-1.119,-1.869 -4.105,-2.99 -8.583,-5.232Z" />
            </svg>
          </Link>
          <Link to={"/query"} id="AskQuestion">
            Ask your query
          </Link>
          <ImagePopup />
        </div>
      ) : (
        <div>
          <Signup />
        </div>
      )}
    </>
  );
};

export default Home;
