import React from "react";
import Courses from "../components/Courses/Courses";
import Products from "../components/Products/Products";

const Services = () => {
  return (
    <div>
      <Courses />
      <Products />
    </div>
  );
};

export default Services;
