import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const BookTab = ({ Book }) => {
  const FillStarsByRating = () => {
    let rating = Math.round(Book.book_rating * 2) / 2;
    if (rating === 0.5) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("halfStar");
    } else if (rating === 1) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("oneStar");
    } else if (rating === 1.5) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("oneAndHalfStar");
    } else if (rating === 2) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("twoStar");
    } else if (rating === 2.5) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("twoAndHalfStar");
    } else if (rating === 3) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("threeStar");
    } else if (rating === 3.5) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("threeAndHalfStar");
    } else if (rating === 4) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("fourStar");
    } else if (rating === 4.5) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("fourAndHalfStar");
    } else if (rating === 5) {
      let ratingDiv = document.getElementById("BookRating");
      ratingDiv.classList.add("fiveStar");
    }
  };
  useEffect(() => {
    FillStarsByRating();
  }, []);

  return (
    <Link to={`${Book.book_id}`}>
      <div className="BookTab">
        <div className="BookImage">
          <img src={Book.book_img[0]} alt={Book.book_title} />
        </div>
        <div className="BookContent">
          <h2>{Book.book_title}</h2>
          <p>{Book.book_author} </p>
          <span id="BookRating"> ★★★★★</span>
          <p>{Book.book_type}</p>
          <div>
            <h4>Rs {Book.book_discounted_price}</h4>
            <div>MRP Rs {Book.book_orignal_price}</div>
          </div>
          <h3 className="BookDeliveryTime">
            Will deliver to you by{" "}
            {Book.book_delivery_details.book_delivery_time}
          </h3>
        </div>
      </div>
    </Link>
  );
};

export default BookTab;
