import React, { useEffect, useState } from "react";
import "./Gallery.css";
import LazyImage from "../components/Lazyimage/LazyImage";

const Gallery = () => {
  const [gallery, setGallery] = useState([]);
  const handleGetGallery = async () => {
    let headers = new Headers();
    headers.append("Access-Control-Allow-Origin", "http://localhost:3000");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    // REQUEST SIGNUP
    await fetch(process.env.REACT_APP_BACKEND_URL + "/getgallery", {
      method: "get",
      headers: headers,
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setGallery(data.gallery);
      });
  };
  useEffect(() => {
    handleGetGallery();
  }, []);
  return (
    <div>
      <div id="Gallery">
        <h1 id="GalleryHeading">Gallery</h1>
        <div className="masonry">
          {gallery.map((image, idx) => {
            return (
              <div className="item">
                <LazyImage key={idx} src={image.pic} className="lazy-image" />
                <p>{image.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
