import React from "react";
import "./About.css";
import About1 from "./../images/About1.jpg";
import About2 from "./../images/About2.jpg";
import About3 from "./../images/About3.jpeg";
import About4 from "./../images/About4.jpeg";
import metateaching from "./../images/metateaching.svg";
const About = () => {
  return (
    <div>
      <section id="heroAbout">
        <div>
          <h2>
            VOE ACADEMY is an initiative to impart quality education to all with
            a minimal fee. We mainly focus on academics of classes 1 to 10th of
            CBSE & State Board (Bihar, UP, Haryana, Punjab) & Language Learning
            through online/offline mode teaching. We also provide specific study
            materials with deep analysis of questions and answers which help in
            concept building. We believe in education for all regardless of the
            socially and Economically Backward Class (SEBC). Our main motive is
            to provide knowledge to all students residing in rural and slum
            areas of PAN India.
          </h2>
          <img src={About1} alt="" id="About1" srcset="" />
        </div>
      </section>
      <section id="AboutBox2">
        <h2>We are working on</h2>
        <ul>
          <li>
            <img src={About3} alt="" />
            <h3>Meta-Teaching</h3>
          </li>
          <li>
            <img src={About1} alt="" />
            <h3>Meta-Cognition</h3>
          </li>
          <li>
            <img src={About4} alt="" />
            <h3>Co-Teaching </h3>
          </li>
          <li>
            <img src={About2} alt="" />
            <h3>Oracy</h3>
          </li>
        </ul>
      </section>
      <section className="AboutBox3">
        <h2>Why Voe Academy?</h2>
        <ul>
          <div>
            <li>
              <h3>
                One Stop Learning Solution for School Learning(K-10)& Language
                Learning.{" "}
              </h3>
            </li>
            <li>
              <h3>
                Provides the best quality tutor(mostly Engineers ) at a flexible
                budget.{" "}
              </h3>
            </li>
          </div>{" "}
          <div>
            <li>
              <h3>Provides tutor for all boards CBSE, ICSE & State board. </h3>
            </li>
            <li>
              <h3>
                Our Vision is to partner with the brightest minds &Democratize.
              </h3>
            </li>
          </div>{" "}
          <div>
            <li>
              <h3>Education for everyone looking to learn.</h3>{" "}
            </li>
            <li>
              <h3>Focused on concept.</h3>
            </li>
          </div>{" "}
          <div>
            {" "}
            <li>
              <h3>Development through question practice.</h3>
            </li>
            <li>
              <h3>
                Scholarship for SEBC whose family income is less than 1 lakh per
                annum.{" "}
              </h3>
            </li>
          </div>
          <div>
            {" "}
            <li>
              <h3>Focused on quality over quantity. </h3>
            </li>
            <li>
              <h3>
                one-to-one personal guidance and session every Sunday through
                online google meet.
              </h3>
            </li>
          </div>
          <div>
            <li>
              <h3>Focus on Meta-Teaching Concepts.</h3>
            </li>
          </div>
        </ul>
      </section>
      <section id="About4">
        <div>
          <h2>Mission</h2>
          <div>
            {/* <div id="MissionImages"></div> */}
            <div>
              <p>
                {" "}
                VOE (Vibes of Education) Academy Mission is to enable children
                in Rural India & Slum areas in tier-1, 2, 3 cities to thrive,
                Supporting both their Physical and emotional health to foster
                Intellectual growth and break the cycle of illiteracy and
                Poverty. Create Scalable & sustainable digital learning in
                India. We will also try our best to close the urban-rural divide
                For educational infrastructure & digital learning by rebuilding
                & expanding tuition cafes and providing cutting-edge learning
                Tools. It acts as a catalyst for creating excellence in the
                Education sector and promoting the convergence of knowledge,
                Information, Technology and Skills. It also provides the
                requisite. Know-how and generates a workforce with the right
                kind of Professional, Instincts, Values, Attitude and expertise.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="AboutBox3">
        <h2>Our values</h2>
        <ul>
          <div>
            <li>
              <h3>To inspire and guide our students toward excellence.</h3>
            </li>
            <li>
              <h3>To provide the highest quality education.</h3>
            </li>
          </div>{" "}
          <div>
            <li>
              <h3>To make them future teacherpreneur.</h3>
            </li>
            <li>
              <h3>
                Respect, Trust, Accountability, Credibility, Transparency.
              </h3>
            </li>
          </div>{" "}
        </ul>
      </section>
    </div>
  );
};

export default About;
