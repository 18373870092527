import React, { useEffect, useState } from "react";
import "./HeroCarousel.css";

const slidesLength = 2;

const HeroCarousel = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      plusSlides(1);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex]);

  function plusSlides(n) {
    setSlideIndex((prevIndex) => (prevIndex + n) % slidesLength);
  }

  function currentSlide(n) {
    setSlideIndex(n - 1);
  }

  function showSlides(n) {
    const slides = document.getElementsByClassName("results-mySlides");
    if (n >= slides.length) {
      setSlideIndex(0);
      return;
    }
    if (n < 0) {
      setSlideIndex(slides.length - 1);
      return;
    }
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slides[n].style.display = "block";
  }
  return (
    <div>
      <div className="results-slideshow-container">
        <div className="results-mySlides">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/results%2Fresult2.jpg?alt=media&token=8a051d54-7b33-4591-b1b0-3570aaa6be8c"
            alt=""
            srcset=""
          />
        </div>
        <div className="results-mySlides">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/results%2Fresult1.jpg?alt=media&token=3d1c7ff7-5c9c-4578-a5a3-e6628edc7607"
            alt=""
            srcset=""
          />
        </div>

        <button className="results-prev" onClick={() => plusSlides(-1)}>
          &#10094;
        </button>
        <button className="results-next" onClick={() => plusSlides(1)}>
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default HeroCarousel;
