import React, { useState } from "react";
import "./HomeTution.css";
import engaging from "./svg/Engagin.svg";
import flexibility from "./svg/flexibility.svg";
import oneToOne from "./svg/one-to-one.svg";
import SafeLearning from "./svg/SafeLearning.svg";

import english from "./svg/english.svg";
import math from "./svg/math.svg";
import chemistry from "./svg/chemistry.svg";
import biology from "./svg/biology.svg";
import accounts from "./svg/accounts.svg";
import economics from "./svg/economics.svg";
import allsubjects from "./svg/allsubjects.svg";

import HTProduct from "./HTProduct";
import { useNavigate } from "react-router-dom";
import TeamCard from "../Team Profile/TeamCard";
import ToastComponent from "../components/Toast/Toast";

const classes = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
];

const subjects = [
  "English",
  "Biology",
  "Chemistry",
  "Physics",
  "Mathematics",
  "Digital Litracy",
];

const HomeTutionOffers = [
  {
    discription: [
      "Home Tutions",
      "Online Tutions",
      "One-on-One class",
      "CBSE/ICSE",
      "Personalized Attention",
    ],
    class: "1 Hour",
    product_catagory: "Time",
    price: "200",
    product_name: "Kindergarden/ Elementary",
    product_id: "KINDER",
  },
  {
    discription: [
      "Home Tutions",
      "Online Tutions",
      "One-on-One class",
      "CBSE/ICSE",
      "Personalized Attention",
    ],
    class: "1 Hour",
    product_catagory: "Time",
    price: "250",
    product_name: "Class VI-VII",
    product_id: "VI-VII",
  },
  {
    discription: [
      "Home Tutions",
      "Online Tutions",
      "One-on-One class",
      "CBSE/ICSE",
      "Personalized Attention",
    ],
    class: "1 Hour",
    product_catagory: "Time",
    price: "300",
    product_name: "Class IX-X",
    product_id: "IX-X",
  },
];

const tutors = [
  {
    name: "Er. Alok Kumar",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2FAlok.jpg?alt=media&token=3bf6cdbd-e45b-4bb2-afee-7ed4f0ebd144",
    about: {
      experience: "3.5 years",
      designation: "Founder & Director",
      qualification:
        "Ex-GURUQ, Ex-Head (Adult Literacy Project RCCH 3080), PCB Design Engineer (Electrowaves Electronics), B.E (Electrical & Electronics Engineering), Panjab University",
    },
  },
  {
    name: "Nainy",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fnainy.jpeg?alt=media&token=a4ba70de-bb59-4435-bd6b-91a37ea9007c",
    about: {
      experience: "5 years",
      designation: "Physics Specialist",
      qualification: "M.Sc (Physics), GATE & NET qualified",
    },
  },
  {
    name: "Siddharth Singh",
    pic: "https://firebasestorage.googleapis.com/v0/b/voe-academy.appspot.com/o/team%2Fsiddhart.jpg?alt=media&token=d8d67813-f709-4699-a009-3d07f8dc5969",
    about: {
      experience: "4 years",
      designation: "Mathematics Specialist",
      qualification: "B.Tech (IT)",
    },
  },
];
const HomeTutionTricity = () => {
  const [toastmessage, setToastmessage] = useState(false);
  const [first_name, setFName] = useState("");
  const [last_name, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [current_class, setClass] = useState("");
  const [subject, setSubject] = useState("");

  const handleBookHomeTution = async () => {
    if (
      !first_name ||
      !last_name ||
      !email ||
      !mobile ||
      !address ||
      !current_class ||
      !subject
    ) {
      setToastmessage("Please enter all input feilds");
      setTimeout(() => setToastmessage(false), 1900);
    } else {
      let headers = new Headers();
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");
      // IS ALL INPUT FEILDS VALID

      await fetch(process.env.REACT_APP_BACKEND_URL + "/bookDemoHomeTution", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          first_name: first_name,
          last_name: last_name,
          email: email,
          mobile: mobile,
          subject: subject,
          address: address,
          current_class: current_class,
        }),
        credentials: "include",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.message === "REQUESTED") {
            setToastmessage(
              "Thanks for getting in touch.Our team will contact you shortly."
            );
            setTimeout(() => setToastmessage(false), 1900);
          } else if (data.message === "ALREADY REQUESTED") {
            setToastmessage(
              "You had already requested for a free class.Our team will contact you soon."
            );
            setTimeout(() => setToastmessage(false), 1900);
          }
        });
    }
  };

  return (
    <div className="HTcontainer">
      <header className="HTheader">
        <h1>Home tution</h1>
        <h2>
          Looking for home tutor / Online tutor? Call now{" "}
          <strong>6284518607</strong>
        </h2>
      </header>
      <main className="HTmain">
        <div>
          <p>
            VOE Academy is student's one point solution for home tutoring/Online
            tutoring in tricity Chandigarh/Mohali/Panchkula
          </p>
          <p className="HTnote">
            Book a free Demo class today with perfect Home tutor and start
            learning.
          </p>
          <button
            className="HTbookbutton"
            onClick={() => {
              window.location.href = "#HTInputForm";
            }}
          >
            Book a demo class
          </button>
        </div>
        <section className="HTsection" id="HTInputForm">
          <h2>Let's connect you with our expert faculty</h2>
          <div className="HTinputForm">
            <input
              type="text"
              placeholder="First name"
              value={first_name}
              onChange={(e) => setFName(e.target.value)}
              className="HTinput-field"
            />
            <input
              type="text"
              placeholder="Last name"
              value={last_name}
              onChange={(e) => setLName(e.target.value)}
              className="HTinput-field"
            />
          </div>
          <div className="HTinputForm">
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="HTinput-field"
            />
            <input
              type="text"
              placeholder="Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="HTinput-field"
            />
          </div>
          <div className="HTinputForm">
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="HTinput-field"
            />
            <select
              value={current_class}
              onChange={(e) => setClass(e.target.value)}
              className="HTselect-field"
            >
              <option value="" className="HTformOptions">
                Select Class
              </option>
              {classes.map((date) => {
                return (
                  <option className="HTformOptions" value={date}>
                    {date}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="HTinputForm">
            <select
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="HTselect-field"
            >
              <option value="" className="HTformOptions">
                Choose Subject
              </option>
              {subjects.map((date) => {
                return (
                  <option className="HTformOptions" value={date}>
                    {date}
                  </option>
                );
              })}
            </select>
          </div>
          <button className="HTbutton" onClick={handleBookHomeTution}>
            Request a Callback
          </button>
          <button
            className="HTbutton"
            onClick={() => {
              window.location.href = "https://wa.me/+918009129336";
            }}
          >
            Whatsapp Us
          </button>
        </section>
        <section className="HTsection">
          <h1>Why parents prefer Home Tution ?</h1>
          <h2 className="HTsectionDetail">
            Customized and Personalized Learining experience with VOE Academy
            Home Tutor/ Home tutoring and online tutoring.
          </h2>
          <ul className="HTlist">
            <div className="HTlistBox">
              {" "}
              <li className="HTlist-item">
                <img src={oneToOne} alt="" className="HTimage" />
                <p>One-on-One Attention</p>
              </li>
              <li className="HTlist-item">
                <img src={SafeLearning} alt="" className="HTimage" />
                <p>Safe Learning Environment</p>
              </li>
            </div>
            <div className="HTlistBox">
              <li className="HTlist-item">
                <img src={flexibility} alt="" className="HTimage" />
                <p>Convenience and flexibility</p>
              </li>
              <li className="HTlist-item">
                <img src={engaging} alt="" className="HTimage" />
                <p>Greater engagement</p>
              </li>
            </div>
          </ul>
          <div>
            <h2>Let's Explore our Tutition Services</h2>
            <h2 className="HTsectionDetail">Redefining the possibilities</h2>
            <ol className="HTlist2">
              <div className="HTlistBox2">
                <li className="HTlist-item2">
                  <p>Home tutoring</p>
                </li>
                <li className="HTlist-item2">
                  <p>Online tutoring</p>
                </li>
              </div>
              <div className="HTlistBox2">
                <li className="HTlist-item2">
                  <p>Group tutions (Batch size 10-20)</p>
                </li>
                <li className="HTlist-item2">
                  <p>
                    Competitive exams (CHS, SANIK, NAVODAYA, RIMS, CTET,
                    IIT-JAM)
                  </p>
                </li>
              </div>
            </ol>
          </div>
        </section>
        <section className="HTsection">
          <h2>Our Subject wise Tutions</h2>
          <h2 className="HTsectionDetail">
            Comprehensive Learining Solutions to help students in wide Range of
            Academic and Extra curricular subjects.
          </h2>
          <ul className="HTlist3">
            <li className="HTlist-item3">
              <img src={math} alt="" />
              <p>Mathematics</p>
            </li>
            <li className="HTlist-item3">
              <img src={chemistry} alt="" />
              <p>Chemistry</p>
            </li>
            <li className="HTlist-item3">
              <img src={biology} alt="" />
              <p>Biology</p>
            </li>
            <li className="HTlist-item3">
              <img src={english} alt="" />
              <p>English</p>
            </li>
            <li className="HTlist-item3">
              <img src={accounts} alt="" />
              <p>Accounts</p>
            </li>
            <li className="HTlist-item3">
              <img src={economics} alt="" />
              <p>Economics</p>
            </li>
            <li className="HTlist-item3">
              <img src={allsubjects} alt="" />
              <p>All Subjects</p>
            </li>
          </ul>
        </section>
        <section className="HTsection">
          <h2>Let's Meet our expert Tutors</h2>
          <div id="HTTutors">
            {tutors.map((Member, id) => {
              return (
                <TeamCard
                  id={id}
                  pic={Member.pic}
                  name={Member.name}
                  about={Member.about}
                />
              );
            })}
          </div>
        </section>
        <section className="HTsection">
          <h2>Fee structure of tutions</h2>
          <h2 className="HTsectionDetail">Affordable price</h2>
          <div className="HTproducts">
            <HTProduct Product={HomeTutionOffers[0]} />
            <HTProduct Product={HomeTutionOffers[1]} />
            <HTProduct Product={HomeTutionOffers[2]} />
          </div>
        </section>
      </main>
      {toastmessage ? <ToastComponent message={toastmessage} /> : <></>}
    </div>
  );
};

export default HomeTutionTricity;
