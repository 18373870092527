import React, { useState } from "react";
import "./HomeTution.css";

import ToastComponent from "../components/Toast/Toast";
const HTProduct = (props) => {
  const [toastmessage, setToastmessage] = useState(false);

  return (
    <div>
      <div id="HTProducts">
        <div id="HTMainProductBanner">
          <div id="HTProductBanner1">
            <h4>{props.Product.product_name}</h4>
            <h5 id="HTProductSubheading">{props.Product.price} RS</h5>
          </div>

          <div id="HTProductBanner2">
            <h5>{props.Product.product_catagory}</h5>
            <div id="HTProductBanner2Line"></div>
            <h5>{props.Product.class}</h5>
          </div>
          <ul id="HTProductBanner3">
            {props.Product.discription.map((point) => {
              return <li>{point}</li>;
            })}
          </ul>
        </div>
      </div>
      {toastmessage ? <ToastComponent message={toastmessage} /> : <></>}
    </div>
  );
};

export default HTProduct;
