import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div id="privacy-policy">
      <h1>VOE Academy Privacy Policy</h1>
      <div className="content">
        {" "}
        <p>
          All online networks involved in content sharing or the ones providing
          users with usable login accounts have various disclaimers. We, like
          everyone else, are sharing ours with you. VOE Academy is an
          online/offline open-content collaborative network for sharing
          information; that is, a voluntary association of individuals and
          groups working to develop a common resource of human knowledge for
          everyone to use. The structure of the VOE Academy Network allows
          anyone with an Internet connection to alter its content. We try the
          best we can to try and provide valuable, up-to-date, relevant,
          accurate, and useful content from our own side. We also do our best to
          try to encourage our users to upload content that is valuable,
          up-to-date, relevant, accurate, and useful. That being said, please be
          advised that nothing found here has necessarily been reviewed by
          people with the expertise required to provide you with complete,
          accurate, or reliable information.
        </p>
        <p>
          That is not to say that you will not find valuable, up-to-date,
          relevant, accurate, and useful information at VOE academy most of the
          time you will. We will try the best we can on our part to make that
          happen. However, VOE academy cannot guarantee the validity of the
          information found here. The content of any given article may recently
          have been changed, vandalized, or altered by someone whose opinion
          does not correspond with the state of knowledge in the relevant
          fields. Also, new content might have been uploaded by individuals
          which may not be correct, valuable, up-to-date, relevant, accurate,
          and useful for our users. If contacted by our users about the presence
          of such content on our website, we will try to remove or edit all such
          content as soon as we can. That being said, we do not guarantee that
          such content will not be online when you view the VOE Academy Network.
          When you log in, it may be in the process of being modified or taken
          down. That being said, we would again like to remind you, that all
          websites and networks like VOE Academy, or similar to VOE Academy have
          disclaimers too.
        </p>
        <p>
          None of the contributors, sponsors, administrators, or anyone else
          connected with VOE academy in any way whatsoever can be responsible
          for the appearance of any inaccurate or libelous information or for
          your use of the information contained in or linked from these web
          pages. Neither we nor the authors warrant or assume any legal
          liability or responsibility for the accuracy, completeness, or
          usefulness of any information, product, simulation, or any other
          process described, in the web-based and video-based lectures. It is
          the responsibility of the user to evaluate the accuracy, completeness,
          and usefulness of any services or products. In no event shall VOE
          academy be liable for any cost or direct, indirect, incidental, or
          consequential damages arising out of or in connection with the use of,
          or inability to use our network
        </p>
        <p>
          Texts, videos, presentations, pdfs, or any other form of content found
          in some material on VOE academy may be found to be copied from a
          copyrighted source. VOE academy does not take any liability for such
          issues. Some texts in some material may be found to be copied from a
          copyrighted source. VOE academy does not take any liability for such
          issues. These notes are specially formatted to help students in their
          studies to help them before, during, and after their exams and excel
          in curricular, extra-curricular as well as co-curricular activities.
          We do not sell or promote any content. The content on VOE academy is
          uploaded or linked by our users, over whom VOE academy exercises no
          control. VOE academy cannot and does not screen the Submissions before
          or after allowing them to be made available to third parties.
          Submissions may contain material or links to files that some people
          find objectionable, inappropriate, or offensive. We cannot guarantee
          that any Submissions made available via the Site will be free of
          objectionable content. We, therefore, assume no responsibility for any
          Submissions on, or otherwise linked to, the Site.
        </p>
        <p>
          We do respond to requests made for the removal of content that might
          be offensive to students or may infringe the copyrights of any third
          party. Please notify us in such cases and be a bit patient for us to
          respond back to you regarding the same.
        </p>
        <p>
          To The fullest extent permitted by law, VOE Academy disclaims any
          responsibility for the deletion, failure of storage, misdelivery, or
          ultimate delivery of any of the uploaded content. The services
          provided by VOE academy, and all submissions and other materials,
          information, products, and services included or made available along
          or in connection with VOE Academy services are being provided “AS IS”
          with no warranties whatsoever. VOE academy expressly disclaims to the
          fullest extent permitted by law all express, implied, and statutory
          warranties, including, without limitation, the warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement of proprietary rights. VOE Academy disclaims any
          warranties regarding the accuracy, security, reliability, timeliness,
          and performance of the VOE Academy services. VOE academy disclaims any
          warranties for any information or advice obtained through any
          submission or any VOE academy service.
        </p>
        <p>
          You understand and agree that you download or otherwise obtain
          material or data through the use of the VOE Academy services,
          including but not limited to any submission(s) at your own discretion
          and risk, and that you will be solely responsible for any damages to
          your computer system or loss of data that results from the download of
          such material or data, including submissions. Please make sure that
          you understand that the information provided here is being provided
          freely and that no kind of agreement or contract is created between
          you and the owners or users of this site, the owners of the servers
          upon which it is housed, the individual VOE Academy contributors and
          submitters, any project administrators, sysops or anyone else who is
          in any way connected with this project or sister projects subject to
          your claims against them directly. Any of the trademarks, service
          marks, collective marks, design rights, or similar rights that are
          mentioned, used or cited in the articles of the VOE Academy the
          property of their respective owners.
        </p>
        <p>
          Publication of information found in VOE academy may not be in
          violation of the laws of the country or jurisdiction from where you
          are viewing this information. The VOE Academy database is stored and
          never ever share with others in any respective. VOE academy does not
          encourage the violation of any laws, and cannot be responsible for any
          violations of such laws, should you link to this domain or use,
          reproduce or republish the information contained herein.
        </p>
        <p>
          If you need specific advice (for example, medical, legal, financial,
          or risk management) please seek a professional who is licensed or
          knowledgeable in that area. VOE academy and all its officers,
          directors, employees, affiliates and agents assume no liability or
          responsibility for, and in no event shall VOE academy , its officers,
          directors, employees, affiliates or agents, be liable for any direct,
          indirect, incidental, special, punitive, or consequential damages
          whatsoever resulting from, any personal injury or property damage, of
          any nature whatsoever, resulting from your access to and use of the
          site, any unauthorized access to or use of our secure servers and/or
          any and all personal information and/or financial information stored
          therein, any interruption or cessation of transmission to or from the
          site, any bugs, viruses, trojan horses, or the like which may be
          transmitted to or through the site, or sites linked to by this site,
          by any third party, and/or any errors or omissions in any
          submission(s) or other content made available by VOE academy , or for
          any loss or damage of any kind incurred as a result of the use of any
          submission(s) or other content posted, emailed, transmitted, or
          otherwise made available via the site, whether based on warranty,
          contract, tort, or any other legal theory, and whether or not the
          company is advised of the possibility of such damages. The foregoing
          limitation of liability shall apply to the fullest extent permitted by
          law in the applicable jurisdiction. VOE academy does not warrant,
          endorse, guarantee, or assume responsibility for any product or
          service advertised or offered by a third party through the site or any
          hyperlinked website or featured in any advertising, and VOE academy
          will not be a party to or in any way be responsible for monitoring any
          transaction between you and third-party providers of products or
          services. You specifically acknowledge that VOE academy shall not be
          liable for user submissions or the defamatory, offensive, or illegal
          conduct of any third party and that the risk of harm or damage from
          the foregoing rests entirely with you.
        </p>
        {/* <ul>
          <li>Email address</li>
          <li>Name</li>
          <li>Phone number</li>
          <li>Date of birth</li>
          <li>Information you share on social networking sites</li>
          <li>
            Information from surveys, contests, quizzes, and other marketing
            activities
          </li>
        </ul> */}
        <h2>Representing yourself:</h2>
        <p>
          Using this website, you represent yourself not as a minor, completely
          understand all the terms, and are subject to legal offenses (if any
          occur). We consider that your Parents are well aware of your VOE
          academy account and the activities that are being carried out by you.
        </p>
        <h2>At the time of Registration:</h2>
        <p>
          You are required to provide your exact information and personal
          details at the time of registration. This will help in associating a
          relationship with the Company and is required for future
          establishments. You will be informed about the important updates of
          the Company referring to these details only. All your information will
          be kept with Team VOE academy and can be ***shared with a third party
          for marketing purposes (or can be used for the growth of the Company)
        </p>
        <h2>Information Communication:</h2>
        <p>
          The user consents to receive emails, SMS & Notifications on the App
          from VOE academy from time to time which informs the users of
          information including but not limited to course updates, updates from
          teachers and friends, and even new features that are added to the
          platform. The validity of the consent is for 12 months from the dating
          platform that was last used.
        </p>
        <h2>Your Account:</h2>
        <p>
          You need VOE academy account to avail the services of VOE academy . If
          your school is registered with VOE academy, your accounts will be
          provided to you by your School administration. However, if your school
          is not registered, you can create your account individually. However,
          there will be a difference between the Registered and Unregistered
          Accounts. No objection regarding the difference in accounts of
          different users will ever be entertained. Your school has the
          authority to keep a check on your activities and can disable your
          account.
        </p>
        <p>
          You are free to use the services of VOE academy and can access all the
          features available to you. You are the only one responsible if your
          account ever gets hacked or some unauthorized use of your account. You
          are advised not to share your Password with anyone and if you notice
          any unauthorized access to your account, you should immediately change
          your Password and report to us at kumaralok7499@gmail.com
        </p>
        <h2>Using our Services:</h2>
        <p>
          You are allowed to use all the features of VOE academy and explore
          them completely. Don’t misuse our services. You should use the
          services as being provided to you and must not try to violate any of
          the procedures. Your account can be analyzed by Team VOE academy
          anytime. We can even deactivate your account without informing you.
          VOE academy does not provide all the content. It’s a student-centric
          website and most of the content is uploaded by students and is
          approved by their faculty. So, each individual holds individual rights
          for whatever content they have uploaded or shared on the website.
        </p>
        <h2>Your Content in our services:</h2>
        <p>
          Most of our features allow you to upload content. Once, you have
          uploaded any content, all the users of VOE academy, all across the
          nation, will view it and can use it for their own benefit.
        </p>
        <p>
          Moreover, you have given permission to VOE academy , to use, modify,
          store and reproduce that content in any form worldwide in the future.
          It can even be published anywhere with the consent of VOE academy.
        </p>
        <h2>Students Guidelines:</h2>
        <p>
          VOE academy provides you with various features that you can enjoy and
          use for your studies. However, while using the features, you must
          consider the following points:
        </p>
        <ul className="privacyPolicyUL">
          <li>
            <h3>Study Material</h3>
            <ul>
              <li>VOE academy creates study material with deep analysis.</li>
              <li>
                The various PPTs, texts, and videos are being uploaded by
                teachers all across the nation.
              </li>
              <li>
                VOE academy plays a role in the up-gradation of the study
                material. It is purely a dynamic structure, where the content
                gets upgraded according to the evaluation done by the users.
              </li>
              <li>
                Only the users with registered schools can upload the study
                material.
              </li>
              <li>
                The student holds the responsibility for the content that has
                been uploaded. VOE academy has no role in the content that is
                available in the Study material, we just provide the
                Students/Teachers with the platform where they can upload.
              </li>
              <li>
                Students must not consider this as the final study material.
                There are some topics/points that may have been skipped or not
                considered. You are advised to consult your textbook to confirm
                the topics.
              </li>
              <li>
                Students must not upload something offensive (such as
                pornography, abusive, vulgar) text, video, or ppt. In case, if
                they do, they abide by the law and legal actions can be taken
                against them.
              </li>
              <li>
                You should genuinely evaluate the Study material and must not
                use offensive words or comments while commenting on the
                material.
              </li>
              <li>
                You can download any file (text/video/ppt) without any
                permission. VOE academy is not responsible for any error that
                may occur during the process of downloading. If any virus or
                malware function (that may harm your computer) is
                encountered/downloaded with any material or during the process
                of downloading, VOE academy has no responsibility for the harm
                caused.
              </li>
              <li>
                You can upload any file (text/video/PPT) at any time to share it
                with your friends. However, the process may need some approval
                from the higher authorities before it gets displayed on the
                website, and will take some time.
              </li>
              <li>
                You can also inform your friends/classmates about the study
                material that you have uploaded by referring to them.
              </li>
              <li>
                You can discuss the problems regarding a particular topic, under
                its category, and can even refer that problem to your
                friends/teachers, whom you think can help you to solve a
                problem.
              </li>
              <li>
                Whenever you refer someone, the information about the reference
                gets displayed on the profile of a particular student.
                Similarly, if someone refers a problem to you, that will be
                displayed in your profile.
              </li>
            </ul>
          </li>
          <li>
            <h3>Quizzes</h3>
            <p>
              The quizzes on the website are just to increase your knowledge.
              They are not related to any of your school subjects or any of the
              quizzes in which you are participating. They must not be
              considered the final material for your studies.
            </p>
          </li>
          <li>
            <h3>VOE Academy Blog</h3>
            <ul>
              <li>
                VOE academy and its editors hold complete responsibility for the
                content being updated in the VOE academy Blog. We try to
                increment the knowledge of students by regularly updating the
                blog and keeping the news up to the date. However, it is a
                student blog and contains the work done by the students and the
                information collected through various resources to take that
                news to the students. The facts are not to be considered, there
                can be some flaws in the facts and incidents.
              </li>
              <li>
                The content can also be copied if the third party agrees to
                share the content but it’ll only be published with proper
                reference from where it has been copied.
              </li>
              <li>
                The students can submit their submissions to
                voeacademy@gmail.com
              </li>
              <li>
                However, the submission does not ensure that it will be
                published in the VOE academy Blog. Any submission will only be
                published after the approval of the Editing VOE academy Team. It
                is the sole decision of the team and no student can interfere in
                the team's work. No objections can be raised against the team if
                any of the submissions are not published. If they like the work,
                they will publish it, or else they won’t.
              </li>
              <li>
                Anything that you have uploaded/updated on the website, can be
                used by the VOE academy team to publish in VOE academy Blog or
                further usage without your permission for the use.
              </li>
            </ul>
          </li>
          <li>
            <h3>Mailing</h3>
            <p>
              It should only be used for educational purposes. If someone tries
              to circulate spam/viruses, he is abiding by the law and legal
              actions can take place. If anyone receives any spam or virus in
              the mail, it is not the responsibility of VOE academy . No claims
              are to be raised against any of the VOE academy members if any
              such harmful activity occurs.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <h2>General Guidelines:</h2>
          </li>
          <li>
            <ul>
              <li>
                <p>
                  No feature of VOE academy is to be used by any user for
                  publicizing any Company/Institution/Product.
                </p>
              </li>
              <li>
                <p>
                  One must not update/upload anything objectionable (pornography
                  and all)
                </p>
              </li>
              <li>
                <p>No virus/spam is to be circulated using VOE academy</p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              If any of the above-mentioned conditions are violated by any of
              the users. He/she is abiding by the law and the VOE academy team
              can file a case against them legally.
            </p>
          </li>
        </ul>
        <h2>Restriction Regarding material on the website:</h2>
        <p>
          VOE Academy is a completely Student-centric website. So, all the
          material that is present on the website is the contribution of the
          students. VOE academy is not responsible for any content on the
          website as all the material on the website is updated by the users.
          None of the users can complain about the content on the website.{" "}
        </p>
        <h2>Data & Information Usage Policy</h2>
        <p>
          VOE Academy reserves the right to use the information obtained by your
          usage and social accounts (Facebook, Google, and others) to customize
          your experience; and connect and invite your friends.
        </p>
        <h2>Modifying and Terminating our Services:</h2>
        <p>
          We are constantly working to improve our Website and offer you the
          Best of our services. We can add new features and can remove the
          previous features without your permission. We can close this website
          anytime. It is possible that at times, you are not able to access your
          account due to some technical problems.
        </p>
        <p>
          It can happen that some problems are encountered while using the
          website, you can always inform the team VOE academy anytime and send
          us the mail, explaining your problem at voeacademy@gmail.com
        </p>
        <h2>Our Warranties and Disclaimer:</h2>
        <p>
          We provide our Services through Registered Schools or Individual
          Registration and expect the users to create a fun learning
          environment. However, we don’t promise any of our Services, we just
          provide you the Services “as it is”.
        </p>
        <p>
          {" "}
          There are no commitments from our side regarding the specifications of
          a particular feature. It can happen that the provided features are not
          able to cater to all your needs.
        </p>
        <h2>Our liability:</h2>
        <p>
          The team at VOE academy has always tried to assure that the
          information you share through your account is safe and secure.
          However, we do not guarantee that it will always be safe as it is.
          There can be threats in the future; hence, we are not responsible for
          harmful actions or safety threats that may occur in the future. You
          cannot claim against any employee of Team VOE Academy for any action
          directly or indirectly.
        </p>
        <h2>Study Group Rules:</h2>
        <ul>
          <li>
            <ul>
              <li>
                If you have any doubts/questions ask them here! But remember
                study groups are meant for educational discussions only!
              </li>
              <li>
                You can only ask a maximum of 5 questions per day. If you have
                more doubts you can simply ask them the next day.
              </li>
              <li>
                If you know an answer to a question, help others with your
                answer! But again remember you can only answer a maximum of 10
                answers per day.
              </li>
              <li>
                You can only give one answer to a question, if you want to add
                something, edit your answer!
              </li>
              <li>If you like any of the answers, UPVOTE!</li>
              <li>
                Help us keep the discussions clean: If you find any questions or
                answers that are non-educational or offensive, REPORT them!
              </li>
              <li>
                Also, if your question/answer is reported more than 3 times, it
                will be deleted and you will be blocked for 1 complete day from
                the study group.
              </li>
            </ul>
          </li>
        </ul>
        <h2>Refund and Cancellation Policy:</h2>
        <p>
          {" "}
          We want you to be satisfied, so all courses are purchased on VOE
          ACADEMY(OPC)PVT.LTD can be refunded within 24 hours of placing the
          order. The request has to be made at voeacademy@gmail.com with your
          email ID and Phone number along with the details of the course
          purchased. Orders paid by Credit/Debit Card will be refunded by credit
          back to the Credit/Debit Card within 5 working days and the refund
          will reflect in the next statement Orders paid by net banking accounts
          will be credited back to a bank account. For all other modes of
          payment, we will send a refund cheque. The cheque will be made in
          favor of the name as in the "billing name" provided at the time of
          placing the order.
        </p>
        <p>
          **A refund cannot be claimed more than once and is also subject to
          extreme usage.
        </p>
        <h2>What is counted as excess usage?</h2>
        <p>
          Accessing any more than 2 Tests, Notes, or Videos after subscribing to
          VOE academy can be considered extreme usage for the purpose of a
          refund. In such cases, a user would not be eligible for a refund, or
          at max, 60% of the purchase amount will be refunded with access
          revoked.
        </p>
        <h2>Mobile Purchases via iOS</h2>
        <p>
          Purchases made through the iOS mobile app cannot be refunded. On the
          iOS app, payments are processed by Apple and as a result, we do not
          have the ability to refund the course. If you would like to request a
          refund, please reach out to Apple Support directly.
        </p>
        <h2>Abuse of Policy</h2>
        <p>
          Finally, students who purchase and refund multiple courses over an
          extended period may be subject to suspension for abuse of the refund
          policy.
        </p>
        <h2>Privacy Policy:</h2>
        <p>
          {" "}
          VOE academy is the owner of this website. We respect your right to
          privacy while being fully committed to fighting spam. Please read the
          following statement to learn about our information-gathering and
          dissemination practices.
        </p>
        <h2>Note</h2>
        <p>
          Our Privacy Policy is subject to change at any time without notice. To
          make sure you are aware of any changes, please review this policy
          periodically. By visiting this website you agree to be bound by the
          terms and conditions of this Privacy Policy. If you do not agree
          please do not use or access our website. By mere use the website, you
          expressly consent to our use and disclosure of your personal
          information in accordance with this Privacy Policy. This Privacy
          Policy is incorporated into and subject to the Terms of Use.
        </p>
        <h2>Collection and Use of Personal Information</h2>
        <p>
          VOE academy may, in the course of or for the purpose of facilitating
          the provision of services to users, collect various personal
          information such as email addresses, names, phone numbers, mobile
          numbers, date of birth, home address, educational and professional
          qualifications, data shared on social networking sites, as well as
          responses to survey forms, contests, quizzes and other marketing and
          promotional activities organized by VOE academy (“Personal
          Information”) The Personal Information you submit to receive
          information and Services from VOE academy will never be disclosed to
          any third party. Provided however, that VOE academy may provide such
          Personal Information or part thereof, to the extent required to
          facilitate the performance of Services to users of voe’s academy
          Services, to its employees, affiliate entities, franchisees,
          sub-contractors, collaborating universities or educational
          institutions, third party email service providers providing marketing
          support to VOE academy , consultants, etc., (“ VOE academy
          Associates”) each of which shall be under a strict obligation to
          maintain the confidentiality of such Personal Information. Provided
          further, that the obligation to maintain the confidentiality of
          Personal Information will not extend to any information that was
          publicly known and generally available in the public domain prior to
          the time of disclosure by you; or to information that becomes publicly
          known and generally available after disclosure by you through no
          action or inaction of ours; information which is already in our
          possession at the time of disclosure by you; is obtained by us from a
          third party without a breach of such third party’s obligations of
          confidentiality.
        </p>
        <p>
          We undertake not to provide anyone other than VOE academy Associates
          your contact details, including your e-mail ID, unless and until you
          enter it yourself. However, people/organizations whose e-mail IDs are
          obtained by us through this site may be included in our own mailing
          list for communications on our product/service innovations and
          relevant news. Recipients of such communications, however, will be
          free to unsubscribe from our mailing lists at any time simply when
          they use the “Unsubscribe” link at the bottom of the mail. We also
          undertake not to make any individual or organization other than an VOE
          academy Associate, privy to the contents of any correspondence with us
          irrespective of whether the correspondence is made electronically
          through this site or by conventional means.
        </p>
        <p>
          Notwithstanding the above, with respect to all Users, VOE academy and
          the VOE academy Associates reserve the right to disclose any Personal
          Information, content, records, or electronic communication of any kind
          (including personal information transmitted through our site) in one
          or more of the following circumstance:
        </p>
        <ul>
          <li>
            <ul>
              <li>To comply with any law, regulation, or government order;</li>
              <li>
                To help prevent and detect identity theft, fraud, and other
                potentially illegal acts;
              </li>
              <li>
                If such disclosure is necessary to operate VOE academy, or any
                of its business units, or VOE academy Associates or for the
                purpose of providing joint or co-branded services with VOE
                academy Associates;
              </li>
              <li>
                In case we plan to merge with, or be acquired by another
                business entity, we may share or sell such information with such
                entity, or in case of re-organization, amalgamation, or
                restructuring of business. Should such a transaction occur that
                other business entity (or the new combined entity) will be
                required to follow this or any equivalent privacy policy with
                respect to your personal information;
              </li>
              <li>
                To protect the property and/or legitimate rights of VOE academy
                or its associates, investors, customers, employees, and
                suppliers.
              </li>
            </ul>
          </li>
        </ul>
        <h2>Use of cookies</h2>
        <p>
          A cookie is a text-only string of information that a website transfers
          to the cookie file of the browser on your computer’s hard disk so that
          the website can remember who you are.
        </p>
        <p>
          A cookie will typically contain the name of the domain from which the
          cookie has come, the ‘lifetime’ of the cookie, and a value, usually a
          randomly generated unique number. When you visit our website we send
          you a cookie. Cookies may be used in the following ways:
        </p>
        <ul>
          <li>
            <ul>
              <li>
                To help us recognize you as a unique visitor (just a number)
                when you return to our website and to allow us to tailor content
                or advertisements, including for the purpose of matching your
                preferred interests or to avoid showing you the same adverts
                repeatedly.
              </li>
              <li>
                To compile anonymous, aggregated statistics that allow us to
                understand how users use our site and to help us improve the
                structure of our website. We cannot identify you personally in
                this way.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Two types of cookies may be used within this website: ‘session
          cookies’, which are temporary cookies that remain in the cookie file
          of your browser until you leave the site, and ‘persistent cookies’,
          which remain in the cookie file of your browser for much longer
          (though how long will depend on the lifetime of the specific cookie).
        </p>
        <h2>Disabling/Enabling Cookies</h2>
        <p>
          You have the ability to accept or decline cookies by modifying the
          settings in your browser. However, you may not be able to use all the
          interactive features of our site if cookies are disabled.
        </p>
        <p>
          There are a number of ways to manage cookies. If you use different
          computers in different locations you will need to ensure that each
          browser is adjusted to suit your cookie preferences. Some modern
          browsers have a feature that will analyze website privacy policies and
          allow a user to control their privacy needs. These are known as ‘PPP’
          features (Privacy Preferences Platform).
        </p>
        <p>
          You can easily delete any cookies that have been installed in the
          cookie folder of your browser. For example, if you are using Microsoft
          Windows Explorer:
        </p>
        <ul>
          <li>
            <ul>
              <li>Open Windows Explorer.</li>
              <li>Click on the Search button on the Toolbar.</li>
              <li>Type ‘cookie’ into the search box for Folders and Files.</li>
              <li>Select My Computer in the Look-In box.</li>
              <li>Click Search Now.</li>
              <li>Double-click on the folders that are found.</li>
              <li>Select any cookie file.</li>
              <li>Hit the Delete button on your keyboard.</li>
            </ul>
          </li>
        </ul>
        <p>
          If you are not using Microsoft Windows Explorer, then you should
          select cookies in the Help function for information on where to find
          your cookie folder.
        </p>
        <h2>IP Addresses</h2>
        <p>
          Your IP address may be used to fix problems that may arise in our
          server or for statistical analysis which can help us to create a
          better experience for our users.
        </p>
        <h2>Payment Security</h2>
        <p>
          We do not collect any payment information on our site. Our payment
          gateway transactions are handled by multiple third-party payment
          gateways, which adhered to the highest levels of network and
          information security compliance. This ensures that your account
          information is well-protected and completely secure. However, VOE
          academy is not responsible for the privacy practices of the
          third-party payment gateways, and in the event of a breach of any
          applicable law, unauthorized disclosure, or misuse of payment
          information by such third-party payment gateways, VOE academy shall
          not be liable in any manner whatsoever. VOE academy also uses cash on
          delivery services for the collection of payments from customers.
          However, VOE academy is not responsible for the privacy practices of
          the companies that it uses to execute cash-on-delivery transactions,
          and in the event of a breach of any applicable law, unauthorized
          disclosure, or misuse of payment information by companies, VOE academy
          shall not be liable in any manner whatsoever.
        </p>
        <h2>Third-party Links/Websites</h2>
        <p>
          The VOE academy n the website may contain links to other websites.VOE
          academy is not responsible for privacy practices or content on
          third-party websites.
        </p>
        <h2>Course Creation</h2>
        <p>
          Once you’ve initiated the process of creating a course on VOE academy
          , the following conditions apply:
        </p>
        <ul>
          <li>
            <ul>
              <li>
                Being the creator of the course, you are solely responsible for
                the content of the course and hold independent authority over
                the content. VOE academy doesn’t hold any responsibility for any
                content uploaded on the website.
              </li>
              <li>
                VOE academy will have the final authority to review the courses
                before publishing them on the website/app and can also remove
                any content/course from the website if there’s any inappropriate
                content. VOE academy holds the final discretion to keep any
                content on the platform.
              </li>
              <li>
                Though VOE academy provides complete assistance and technical
                support for creating an ideal course, the roles and
                responsibilities of VOE academy are confined only to providing
                the required technology. Uploading Content in an appropriate
                manner is the responsibility of the Course Creator.
              </li>
              <li>
                Even though VOE academy algorithms are designed in a manner to
                promote quality content over the platform and VOE academy
                doesn’t hold any liability to promote/market any course
                published on the website. That said, analytical data will be
                shared on the platform (or otherwise) with the course creator to
                help them improve their courses according to the need of the
                audience. Also, VOE academy is open to collaborating for Course
                Promotions.
              </li>
              <li>
                For putting paid content/courses, a Master Service Agreement
                needs to be signed by both parties to proceed. Paid courses will
                only be launched after the final approval from VOE academy and
                all the hold conditions also hold true for the same. For
                discussing more on the same, we can easily be reached at
                voeacademy@gmail.com.
              </li>
            </ul>
          </li>
        </ul>
        <h2>Use of YouTube Videos and API</h2>
        <p>
          VOE Academy follows all YouTube Terms and Conditions in accordance
          with the Terms of Service of Google/YouTube which can be checked at
          the following links: https://policies.google.com/privacy,
          https://www.youtube.com/static?template=terms
        </p>
        <h2>Use of Facebook/Instagram</h2>
        <p>
          When you sign in or create an account on our App and Website using
          your Facebook account, we will have access to certain information such
          as your name, profile picture, gender, and email address (if there is
          one associated with your Facebook account), in accordance with the
          authorization procedures determined by Facebook.
        </p>
        <p>
          We use this data to create your VOE academy account. You can request
          for your personal data to be deleted by contacting us at
          kumaralok7499@gmail.com.
        </p>
        <h2>
          How to unsubscribe your contact number from getting any SMS from VOE
          Academy ?
        </h2>
        <p>
          If you wish to remove your contact number from getting any updates
          from VOE academy , you can simply send us an email to
          voeacademy@gmail.com with your contact number and type unsubscribe
          along with it. For Example:{`<UNSUBSCRIBE 9888xxxxx>`}
        </p>
        <h2>Collection and Use of Personal Information</h2>
        <p>
          VOE academy reserves the right to use your contact details (your email
          and contact number) to send you updates about feature updates and
          offers valid for you.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
