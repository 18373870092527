import React from "react";
import "./Blogs.css";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const navigate = useNavigate();
  const BlogLinkBox = ({ blog_link, title }) => {
    return (
      <div>
        <a href={blog_link} id="BlogLinkBox">
          <h1>{title}</h1>
          <button
            onClick={() => {
              navigate({ blog_link });
            }}
          >
            Read more
          </button>
        </a>
      </div>
    );
  };

  return (
    <div id="BlogPage">
      <div>
        <h1>Blogs</h1>
      </div>
      <BlogLinkBox
        blog_link={"/blogs/chs-bhu-entrance-exam-2024"}
        title={"CHS BHU Entrance Exam (2024)"}
      />
      <BlogLinkBox
        blog_link={"/blogs/jnvst-2024"}
        title={"Navodaya Vidyalaya Samiti Selection Test (JNVST) for Class 9"}
      />
      <BlogLinkBox
        blog_link={"/blogs/sanik-school-entrance-exam-2024"}
        title={"All India Sainik Schools Entrance Examination (AISSEE) 2023"}
      />
      <BlogLinkBox
        blog_link={
          "/blogs/Mid-Day-Meal-Scheme-Improving-Nutrition-and-Education"
        }
        title={
          "Understanding India's Mid-Day Meal Scheme: Improving Nutrition and Education"
        }
      />
      <BlogLinkBox
        blog_link={"/blogs/Rashtriya-Madhyamik-Shiksha-Abhiyan"}
        title={
          "Rashtriya Madhyamik Shiksha Abhiyan: A Step Towards Quality Education"
        }
      />
      <BlogLinkBox
        blog_link={"/blogs/Sarva-Shiksha-Abhiyan"}
        title={
          "Sarva Shiksha Abhiyan: Transforming Elementary Education in India"
        }
      />
      <BlogLinkBox
        blog_link={"/blogs/National-Education-Policy-2020"}
        title={"National Education Policy 2020: A Comprehensive Overview"}
      />
      <BlogLinkBox
        blog_link={"/blogs/Comprehensive-Guide-to-Education-Loans-in-India"}
        title={"Comprehensive Guide to Education Loans in India: SBI Example"}
      />
      <BlogLinkBox
        blog_link={"/blogs/Literacy-Rates-in-India-2023"}
        title={"Literacy Rates in India: A Comprehensive Analysis"}
      />
      <BlogLinkBox
        blog_link={"/blogs/employment-generation-pmegp"}
        title={
          "Employment Generation through the Prime Minister Employment Creation Scheme"
        }
      />
    </div>
  );
};

export default Blogs;
